import React from 'react';
import classnames from 'classnames';
import './style.scss';

export default props => {
	const {name, onChange, checked, disabled} = props;

	const classes = classnames('ptr-Checkbox', {
		'is-disabled': disabled,
	});

	return (
		<label className={classes}>
			<input
				type="checkbox"
				onChange={onChange}
				checked={checked || false}
				disabled={disabled || false}
			/>
			<span>{name}</span>
		</label>
	);
};
