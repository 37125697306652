import {connect} from '@gisatcz/ptr-state';

import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		activeCaseKey: Select.cases.getActiveKey(state),
		cases: Select.dromas.getCasesForSelect(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		return {
			setRouterToRoot: () => {
				dispatch(
					Action.dromas.updateAppUrl('home', {
						periodKey: null,
						styleKey: null,
						featureId: null,
						case: null,
						tabKey: null,
					})
				);
			},
			onCaseSelect: caseUrl => {
				dispatch(Action.selections.setActiveSelectionFeatureKeysFilterKeys([]));
				dispatch(Action.dromas.updateAppUrl('case', {case: caseUrl}));
			},
			onUnmount: () => {
				//clear load views indexed
				// dispatch(Action.views.useIndexedClear(componentId));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
