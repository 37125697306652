const ActivityConfirmed = {
	0: 'Operace nebo stav DPB nepotvrzeny',
	1: 'Operace nebo stav DPB potvrzeny',
};

const ActivityExtent = {
	0: 'Část DPB',
	1: 'Celý DPB',
};

const ActivityType = {
	OB: 'Obnova',
	KL: 'Klučení',
	NE: 'Bez operace klučení nebo obnova',
};

const AnomalyType = {
	0: 'Anomálie nezjištěna',
	1: 'Opouštění, ponechání ladem',
	2: 'Jiné anomálie',
};

const AnomalySubtype = {
	21: 'Změna kultury',
	22: 'Výskytem holé půdy (např. stavby a stavební činnosti)',
	23: 'Pokles životaschopných jedinců',
	24: 'Vyšší výskyt chlorofylu',
	25: 'Vyklučení nebo dosadba/obnova',
	'21,22':
		'Změna kultury; Výskytem holé půdy (např. stavby a stavební činnosti)',
	'21,22,23':
		'Změna kultury; Výskytem holé půdy (např. stavby a stavební činnosti); Pokles životaschopných jedinců',
	'21,22,23,25':
		'Změna kultury; Výskytem holé půdy (např. stavby a stavební činnosti); Pokles životaschopných jedinců; Vyklučení nebo dosadba/obnova',
	'21,22,25':
		'Změna kultury; Výskytem holé půdy (např. stavby a stavební činnosti); Vyklučení nebo dosadba/obnova',
	'21,23': 'Změna kultury; Pokles životaschopných jedinců',
	'21,23,25':
		'Změna kultury; Pokles životaschopných jedinců; Vyklučení nebo dosadba/obnova',
	'21,25': 'Změna kultury',
	'21,24,25':
		'Změna kultury; Vyšší výskyt chlorofylu; Vyklučení nebo dosadba/obnova',
	'21,24': 'Změna kultury; Vyšší výskyt chlorofylu',
	'22,23':
		'Výskytem holé půdy (např. stavby a stavební činnosti); Pokles životaschopných jedinců',
	'22,23,25':
		'Výskytem holé půdy (např. stavby a stavební činnosti); Pokles životaschopných jedinců; Vyklučení nebo dosadba/obnova',
	'22,24':
		'Výskytem holé půdy (např. stavby a stavební činnosti); Vyšší výskyt chlorofylu',
	'22,25':
		'Výskytem holé půdy (např. stavby a stavební činnosti); Vyklučení nebo dosadba/obnova',
	'23,24': 'Pokles životaschopných jedinců; Vyšší výskyt chlorofylu',
	'23,25': 'Pokles životaschopných jedinců; Vyklučení nebo dosadba/obnova',
	'24,25': 'Vyšší výskyt chlorofylu; Vyklučení nebo dosadba/obnova',
};

const InSituPhoto = {
	0: 'Ne',
	1: 'Ano',
};

const KULTURAKOD = {
	'case_sece-pastvy': {
		T: 'Trvalý travní porost',
		G: 'Travní porost na orné půdě',
	},
	'case_kluceni-obnova': {
		V: 'Vinice',
		S: 'Ovocný sad',
	},
	case_anomalie: {
		V: 'Vinice',
		C: 'Chmelnice',
		S: 'Ovocný sad',
	},
};

const CombinedManagement = {
	0: 'Zjištěna pouze seč nebo pastva',
	1: 'Zjištěna současně seč i pastva',
};

const Management = {
	'No management': 'Nezjištěn žádný management',
	Mowing: 'Zjištěna seč',
	Grazing: 'Zjištěna pastva',
	Combined: 'Zjištěn smíšený management',
};

const MonitoringStatus = {
	0: 'Ne',
	1: 'Ano',
};

const NoMonitoringReason = {
	1: 'Geometrie DPB nesplňuje podmínky monitoringu',
	2: 'Družicová data nejsou k dispozici nebo je nelze vyhodnotit',
	3: 'Ostatní důvody',
};

const PartialMowingIndex = {
	0: 'Částečná seč nebyla zjištěna',
	1: 'Částečná seč zjištěna s nižší mírou spolehlivosti',
	2: 'Částečná seč zjištěna s vyšší mírou spolehlivosti',
};

const ReliabilityIndex = {
	1: 'Nižší míra spolehlivosti',
	2: 'Vyšší míra spolehlivosti',
};

const RecordStatus = {
	0: 'Záznam detekce beze změny',
	'-1': 'Záznam detekce zrušen',
};

const SubsidyScheme = {
	AEKO_A: 'AEKO A Integrovaná produkce ovoce ',
	AEKO_B: 'AEKO B Integrovaná produkce révy vinné',
	NAEKO_A: 'NAEKO A Integrovaná produkce ovoce ',
	NAEKO_B: 'NAEKO B Integrovaná produkce révy vinné',
};

export default {
	ActivityConfirmed,
	ActivityExtent,
	ActivityType,
	AnomalyType,
	AnomalySubtype,
	CombinedManagement,
	GrazingReliabilityIndex: ReliabilityIndex,
	KULTURAKOD,
	Management,
	MonitoringStatus,
	MowingReliabilityIndex: ReliabilityIndex,
	NoMonitoringReason,
	PartialMowingIndex,
	RecordStatus,
	InSituPhoto,
	ReliabilityIndex,
	SubsidyScheme,
};
