import React from 'react';

const About = () => {
	return (
		<div className="dromas-About">
			<div>
				<p>
					Družicový dálkový průzkum Země představuje jedinečnou informační
					technologii, která nabízí data a nástroje pro operační celorepublikový
					monitoring zemědělských plodin. Projekt DROMAS je řešen společností{' '}
					<a
						href="http://gisat.cz/content/cz"
						target="_blank"
						rel="noopener noreferrer"
						title="GISAT | www.gisat.cz"
					>
						Gisat
					</a>{' '}
					za podpory{' '}
					<a
						href="https://www.esa.int/"
						target="_blank"
						rel="noopener noreferrer"
						title="Evropská kosmická agentura | www.esa.int"
					>
						Evropské kosmické agentury
					</a>{' '}
					v rámci programu{' '}
					<a
						href="https://business.esa.int/"
						target="_blank"
						rel="noreferrer noopener"
						title="ESA Business Applications | business.esa.int"
					>
						ESA Business Applications
					</a>
					. V rámci tohoto projektu byla připravena a ověřena řada služeb
					zaměřených na mapování stavu a sledování vývoje zemědělských porostů.{' '}
					<a
						href="https://www.szif.cz/cs"
						target="_blank"
						rel="noreferrer noopener"
						title="Státní zemědělský a intervenční fond | www.szif.cz"
					>
						Státní zemědělský intervenční fond (SZIF)
					</a>{' '}
					představuje jednoho z potenciálních uživatelů připravovaných služeb,
					které mohou být využity při zavádění Systému monitorování plochy (AMS)
					a Kontrol pomocí monitoringu.
				</p>
				<p>
					Aktivity řešené v rámci poslední fáze projektu se zaměřily na vývoj
					inovativních metod pro mapování stavu trvalých kultur (chmelnice,
					vinice a sady) a sledování managementu trvalých travních porostů (seč
					a pastva). Ukázky výsledků praktického oveření vyvinutých metod a
					připravených služeb představují hlavní náplň tohoto webového portálu.
				</p>
			</div>
		</div>
	);
};

export default About;
