import {CHANGE_PAGE} from '@gisatcz/ptr-router';

const INITIAL_STATE = {};

const setPage = (state, action) => {
	return {
		name: action.name,
		params: action.params,
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CHANGE_PAGE:
			return setPage(state, action.payload);
		default:
			return state;
	}
};
