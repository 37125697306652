import {connect, setRecomputeState} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import {mapSetKey, photoLayerKey} from '../../../../constants/app';
import Actions from '../../../../state/Action';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';

const componentKey = 'DpbDetail';

const mapStateToProps = (state, ownProps) => {
	setRecomputeState(state);
	const mapSetActiveMapKey = Select.maps.getMapSetActiveMapKey(
		state,
		mapSetKey
	);
	const capturePointsDisplayed = Select.maps.getLayerStateByLayerKeyAndMapKey(
		state,
		mapSetActiveMapKey,
		photoLayerKey
	);

	const router = Select.router.getCurrent(state);
	const selectedFeatureKey = router?.params?.parsedQueryString?.featureId;

	//wait for active period
	const activePeriodKey = Select.periods.getActiveKey(state);

	return {
		activePeriodKey,
		capturePointsDisplayed: !!capturePointsDisplayed,

		componentKey,
		attributesMetadata: Select.attributes.getAll(state),
		mowingGrazingAttributes:
			Select.dromas.getMowingGrazingAttributes(componentKey),
		photos: Select.dromas.getPhotosForSelectedDpb(componentKey),
		selectedFeatureKey,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		return {
			onSelectedFeatureChange: () => {
				dispatch(Actions.selections.clearFeatureKeysFilter('points'));
				dispatch(Action.dromas.useDataForDpbDetail(componentKey));
			},

			onDisplayPhotosPointsChanged: displayPhotoLayer => {
				dispatch(Actions.dromas.togglePhotoLayer(displayPhotoLayer));
			},
			onPhotoSelect: featureId => {
				dispatch(Actions.dromas.selectPoint(photoLayerKey, [featureId]));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
