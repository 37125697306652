import React, {useEffect} from 'react';
import {useAnalytics} from 'use-analytics';
import {isNumber as _isNumber} from 'lodash';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import propTypes from 'prop-types';
import Legend from './Legend';

import './style.scss';

const CaseStyle = ({activeStyleKey, styles, legendName, onMount, onSelect}) => {
	const {track} = useAnalytics();

	// on tab change
	useEffect(() => {
		if (activeStyleKey) {
			track('activeStyle', {
				style: activeStyleKey,
			});
		}
	}, [activeStyleKey]);

	useEffect(() => {
		if (onMount) {
			onMount();
		}
	}, []);

	return (
		<div className="dromas-CaseStyle">
			<span className="dromas-CaseStyle-label">Vyberte téma</span>
			<ButtonSwitch
				className="dromas-CaseStyle-switch"
				onClick={onSelect}
				ghost
			>
				{styles &&
					styles.map(style => {
						return (
							<ButtonSwitchOption
								key={style.key}
								value={style.key}
								active={activeStyleKey === style.key}
							>
								{style.data.nameDisplay}
							</ButtonSwitchOption>
						);
					})}
			</ButtonSwitch>
			<Legend name={legendName} />
		</div>
	);
};

CaseStyle.propTypes = {
	activeStyleKey: propTypes.string,
	legendName: propTypes.string,
	onMount: propTypes.func,
	onSelect: propTypes.func,
	styles: propTypes.array,
};

export default CaseStyle;
