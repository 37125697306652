import React, {useEffect} from 'react';
import classnames from 'classnames';
import {find as _find, findIndex as _findIndex} from 'lodash';
import PropTypes from 'prop-types';
import {Mouse, Months, Years, MapTimeline} from '@gisatcz/ptr-timeline';
import {connects} from '@gisatcz/ptr-state';

import {
	compositeLayerTemplateKey,
	orthophotoEastLayerTemplateKey,
	orthophotoWestLayerTemplateKey,
} from '../../../../constants/app';

const MapTimelinePresentation = MapTimeline.MapTimelinePresentation;
const LayerRowPresentation = MapTimeline.LayerRowPresentation;
const LayerRowItemPresentation = MapTimeline.LayerRowItemPresentation;
const LayerRowPeriodItemPresentation =
	MapTimeline.LayerRowPeriodItemPresentation;

const LayerRowComponent = connects.timeline.LayerRow(LayerRowPresentation);
const LayerRowItemComponent = connects.timeline.LayerRowItem(
	LayerRowItemPresentation
);
const LayerRowPeriodItemComponent = connects.timeline.LayerRowPeriodItem(
	LayerRowPeriodItemPresentation
);

const LayerRowItemComponentWrapped = props => (
	<LayerRowItemComponent
		{...props}
		LayerRowPeriodItemComponent={LayerRowPeriodItemComponent}
	/>
);
const LayerRowComponentWrapped = props => (
	<LayerRowComponent
		{...props}
		LayerRowItemComponent={LayerRowItemComponentWrapped}
	/>
);

import './style.scss';

const LEVELS = [
	{
		level: 'year',
		end: 2,
	},
	{
		level: 'month',
		end: 5,
	},
];

const Levels = props => {
	const {activeLevel} = props;
	switch (activeLevel) {
		case 'year':
			return React.createElement(Years, {...props, key: 'year'});
		case 'month':
			return React.createElement(Months, {...props, key: 'month'});
	}
	return React.createElement(Months, {...props, key: 'month'});
};

const getHoverContent = (x, time, evt, hoverContext, layerRows) => {
	const clientY = evt.clientY;

	// remove timeline as a overlay
	const hoveredOverlays = hoverContext?.hoveredItems?.filter(
		i => i.key !== 'timeline'
	);

	let top = 0;
	// select row by mouse position
	const layerRowMouseIntersection = layerRows?.find(layerRow => {
		top = top + (layerRow.lineHeight - layerRow.elementHeight) / 2;
		const layerRowTop = top;
		top = top + layerRow.elementHeight;
		const layerRowBottom = top;
		top = top + (layerRow.lineHeight - layerRow.elementHeight) / 2;
		const mouseIntersectRow =
			layerRowTop <= clientY && layerRowBottom >= clientY;
		return mouseIntersectRow;
	});

	//if layerRowMouseIntersection is for composit layer
	const hoverComposites =
		hoveredOverlays[0]?.overlay?.origin?.originLayer?.layerState
			?.layerTemplateKey === compositeLayerTemplateKey; //"35908a0f-c769-48ba-ae67-efbb101a15e5"
	const hoverOrthophotoEast =
		hoveredOverlays[0]?.overlay?.origin?.originLayer?.layerState
			?.layerTemplateKey === orthophotoEastLayerTemplateKey; //"35908a0f-c769-48ba-ae67-efbb101a15e5"
	const hoverOrthophotoWest =
		hoveredOverlays[0]?.overlay?.origin?.originLayer?.layerState
			?.layerTemplateKey === orthophotoWestLayerTemplateKey; //"35908a0f-c769-48ba-ae67-efbb101a15e5"

	let descriptionElm = null;
	if (hoverComposites) {
		descriptionElm = (
			<div>{`${hoveredOverlays[0].overlay?.origin?.originPeriod?.data?.nameDisplay}`}</div>
		);
	}

	if (hoverOrthophotoEast) {
		descriptionElm = <div>Ortofoto východ</div>;
	}

	if (hoverOrthophotoWest) {
		descriptionElm = <div>Ortofoto západ</div>;
	}

	const intersectionOverlaysElms =
		hoveredOverlays?.length > 0 && layerRowMouseIntersection ? (
			<div
				key={'layerRowMouseIntersection.key'}
				className={'ptr-timeline-tooltip-layer'}
			>
				<div>
					<span className="dot" style={{backgroundColor: 'red'}}></span>
				</div>
				<div>
					<div>
						<em>{layerRowMouseIntersection.legend.title}</em>
					</div>
					{descriptionElm}
				</div>
			</div>
		) : null;

	return (
		<div>
			<div className={'ptr-timeline-tooltip-time'}>
				<b>{`${time.format('YYYY')}`}</b>-<b>{`${time.format('MM')}`}</b>-
				<b>{`${time.format('DD')}`}</b>
			</div>
			{intersectionOverlaysElms}
		</div>
	);
};

const Timeline = ({
	onLayerClick,
	layers,
	onMount,
	activeCaseKey,
	timelinePeriod,
}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	});

	const classes = classnames('dromas-Timeline');

	return (
		<div className={classes}>
			{layers && activeCaseKey ? (
				<MapTimelinePresentation
					LayerRowComponent={LayerRowComponentWrapped}
					mapKey={'map-1'}
					getHoverContent={(...rest) => getHoverContent(...rest, layers)}
					periodLimit={timelinePeriod}
					initPeriod={timelinePeriod}
					vertical={false}
					levels={LEVELS}
					selectMode={true}
					layers={layers}
					legend={true}
					onLayerClick={onLayerClick}
				>
					<Levels />
					<Mouse mouseBufferWidth={20} key="mouse" />
				</MapTimelinePresentation>
			) : null}
		</div>
	);
};

Timeline.propTypes = {
	layers: PropTypes.array,
	activeLayers: PropTypes.array,
	onLayerClick: PropTypes.func,
};

export default Timeline;
