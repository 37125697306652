import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import {connects} from '@gisatcz/ptr-state';
import {AdjustableColumns} from '@gisatcz/ptr-atoms';
import {
	ReactLeafletMap,
	PresentationMap,
	MapSet,
	MapControls,
	MapScale,
} from '@gisatcz/ptr-maps';
import {useAnalytics} from 'use-analytics';
import {mapSetKey} from '../../../constants/app';
import MapContainer from './Map/MapContainer';
import MapWrapper from './Map/MapWrapper';
import Header from './Header';
import SidePanel from './SidePanel';
import DpbDetail from './DpbDetail';
import CaseAbout from './CaseAbout';
import CaseOverview from './Visualizations';
import Timeline from './Timeline';
import SimpleLayersControl from './Map/SimpleLayersControl';
import MapAttribution from './Map/MapAttribution';

import './style.scss';

const ConnectedMap = MapContainer(PresentationMap);
const ConnectedMapSet = connects.MapSet(MapSet);

const MapComponentsPositionWrapper = props => {
	const {children, ...restProps} = props;
	return (
		<div className={`dromas-MapSetControls`}>
			{React.Children.map(children, child => {
				return React.cloneElement(child, {...restProps});
			})}
		</div>
	);
};

const centerMapContent = viewLimits => (
	<>
		<ConnectedMapSet
			wrapper={MapWrapper}
			stateMapSetKey={mapSetKey}
			mapComponent={ReactLeafletMap}
			connectedMapComponent={ConnectedMap}
		>
			<MapComponentsPositionWrapper>
				<MapScale className="dromas-MapScale" />
				<MapAttribution />
			</MapComponentsPositionWrapper>
			<SimpleLayersControl />
			<MapControls levelsBased zoomOnly viewLimits={viewLimits} />
		</ConnectedMapSet>
	</>
);

const getTimelineComponent = () => {
	return (
		<div className="dromas-Timeline-wrapper ptr-dark">
			<Timeline />
		</div>
	);
};

const getCenterColumn = viewLimits => {
	let rows = [centerMapContent(viewLimits), getTimelineComponent()];

	return {
		className: 'ptr-light',
		render: props => (
			<div className={'ptr-layout-columns ptr-center-column'}>{rows}</div>
		),
	};
};

const getSidePanel = (onTabClick, activeTab) => {
	const tabs = [
		{
			key: 'visualization',
			title: 'Témata',
			component: CaseOverview,
		},
		{
			key: 'dpbDetail',
			title: 'Díl půdního bloku (DPB)',
			component: DpbDetail,
		},
		{
			key: 'aboutCase',
			title: 'Popis služby',
			component: CaseAbout,
		},
	];

	return {
		className: 'ptr-dark dromas-SidePanel',
		width: '42rem',
		render: props => (
			<SidePanel
				defaultActiveTabIndex={0}
				activeTabKey={activeTab}
				onTabClick={onTabClick}
				tabs={tabs}
			/>
		),
	};
};

const getEmptySidePanel = () => {
	return {
		className: 'ptr-dark dromas-SidePanel',
		width: '42rem',
		render: props => <div></div>,
	};
};

const getColumnsContent = (
	onTabClick,
	activeTab,
	activeCaseKey,
	viewLimits
) => {
	const columns = [getCenterColumn(viewLimits)];
	if (activeCaseKey) {
		columns.push(getSidePanel(onTabClick, activeTab));
	} else {
		columns.push(getEmptySidePanel());
	}
	return columns;
};

const AppContent = ({onTabClick, activeTab, activeCaseKey, viewLimits}) => {
	const {track} = useAnalytics();

	// on tab change
	useEffect(() => {
		track('tab', {
			tab: activeTab,
		});
	}, [activeTab]);

	const columnsContent = getColumnsContent(
		onTabClick,
		activeTab,
		activeCaseKey,
		viewLimits
	);

	return (
		<div className={'dromas-App'}>
			<Header />
			<div className={'dromas-AppContent'}>
				<AdjustableColumns fixed content={columnsContent} />
			</div>
		</div>
	);
};

AppContent.propTypes = {
	onTabClick: propTypes.func,
	activeTabKey: propTypes.string,
	activeCaseKey: propTypes.string,
	viewLimits: propTypes.object,
};

export default AppContent;
