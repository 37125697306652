import React from 'react';
import DonutChart from '../chartComponents/DonutChart';
import StackedBarChart from '../chartComponents/StackedBarChart';

const dataCount = [
	{
		id: 'noMonitoring',
		label: 'Vyřazené DPB',
		value: 0.61,
		color: '#ff7f00',
	},
	{
		id: 'monitoring',
		label: 'Monitorované DPB',
		value: 0.39,
		color: '#aaaaaa',
	},
];

const dataArea = [
	{
		id: 'noMonitoring',
		label: 'Vyřazené DPB',
		value: 0.17,
		color: '#ff7f00',
	},
	{
		id: 'monitoring',
		label: 'Monitorované DPB',
		value: 0.83,
		color: '#aaaaaa',
	},
];

const colors = {
	monitoring: '#aaaaaa',
	noMonitoring: '#ff7f00',
};

const labels = {
	monitoring: 'Monitorované DPB',
	noMonitoring: 'Vyřazené DPB',
};

const dataChmelnice = [
	{
		type: 'Počet DPB',
		monitoring: 0.91,
		noMonitoring: 0.09,
	},
	{
		type: 'Výměra DPB',
		monitoring: 0.97,
		noMonitoring: 0.03,
	},
];

const dataSady = [
	{
		type: 'Počet DPB',
		monitoring: 0.51,
		noMonitoring: 0.49,
	},
	{
		type: 'Výměra DPB',
		monitoring: 0.9,
		noMonitoring: 0.1,
	},
];

const dataVinice = [
	{
		type: 'Počet DPB',
		monitoring: 0.22,
		noMonitoring: 0.78,
	},
	{
		type: 'Výměra DPB',
		monitoring: 0.72,
		noMonitoring: 0.28,
	},
];

const NoMonitoringReasonAnomalie = () => {
	return (
		<>
			<h4>Rozložení monitorovaných a vyřazených DPB</h4>
			<div className="dromas-Charts-section two-columns">
				<div>
					<h5>Podíl podle počtu DPB</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataCount} />
					</div>
				</div>
				<div>
					<h5>Podíl podle výměry DPB</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataArea} />
					</div>
				</div>
			</div>
			<div className="dromas-Charts-section three-columns bar-charts">
				<div>
					<h5>Chmelnice</h5>
					<div className="dromas-ChartWrapper">
						<StackedBarChart
							data={dataChmelnice}
							keys={['monitoring', 'noMonitoring']}
							indexBy="type"
							colorsDef={colors}
							labelsDef={labels}
						/>
					</div>
				</div>
				<div>
					<h5>Sady</h5>
					<div className="dromas-ChartWrapper">
						<StackedBarChart
							data={dataSady}
							keys={['monitoring', 'noMonitoring']}
							indexBy="type"
							colorsDef={colors}
							labelsDef={labels}
						/>
					</div>
				</div>
				<div>
					<h5>Vinice</h5>
					<div className="dromas-ChartWrapper">
						<StackedBarChart
							data={dataVinice}
							keys={['monitoring', 'noMonitoring']}
							indexBy="type"
							colorsDef={colors}
							labelsDef={labels}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default NoMonitoringReasonAnomalie;
