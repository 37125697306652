import {commonActionTypes} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

const specificActionTypes = utils.deepKeyMirror({
	ESADROMAS21: {
		ADD_MOWING_GRAZING_ATTRIBUTES_BY_DPB_CODE: null,
	},
});

export default {
	...commonActionTypes,
	...specificActionTypes,
};
