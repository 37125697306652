import React from 'react';
import DonutChart from '../chartComponents/DonutChart';

const dataVinice = [
	{
		id: 'noMonitoring',
		label: 'Operace nepotvrzeny',
		value: 0.08,
		color: '#ff7f00',
	},
	{
		id: 'monitoring',
		label: 'Operace potvrzeny',
		value: 0.92,
		color: '#45b558',
	},
];

const dataSady = [
	{
		id: 'noMonitoring',
		label: 'Operace nepotvrzeny',
		value: 0.09,
		color: '#ff7f00',
	},
	{
		id: 'monitoring',
		label: 'Operace potvrzeny',
		value: 0.91,
		color: '#45b558',
	},
];

const ActivityConfirmed = () => {
	return (
		<>
			<h4>Výsledky kontroly</h4>
			<p>
				Distribuce počtu potvrzených a nepotvrzených DPB pro vinice a ovocné
				sady
			</p>
			<div className="dromas-Charts-section two-columns">
				<div>
					<h5>Vinice</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataVinice} legendItemWidth={140} />
					</div>
				</div>
				<div>
					<h5>Sady</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataSady} legendItemWidth={140} />
					</div>
				</div>
			</div>
		</>
	);
};

export default ActivityConfirmed;
