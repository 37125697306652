import React from 'react';
import './style.scss';
import {
	LegendCategories,
	LegendCategory,
	LegendCategoryLine,
} from './components';

const ActivityConfirmed = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#45b558"
				outlineColor="#45b558"
				label="Operace nebo stav DPB potvrzeny"
			/>
			<LegendCategory
				fillColor="#ff7f00"
				outlineColor="#ff7f00"
				label="Operace nebo stav DPB nepotvrzeny"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="DPB nesplňuje podmínky pro monitoring"
			/>
		</LegendCategories>
	);
};

const ActivityType = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#77cf29"
				outlineColor="#77cf29"
				label="Obnova"
			/>
			<LegendCategory
				fillColor="#3e98f7"
				outlineColor="#3e98f7"
				label="Klučení"
			/>
			<LegendCategory
				fillColor="#be59de"
				outlineColor="#be59de"
				label="Bez operace klučení nebo obnova"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="DPB nesplňuje podmínky pro monitoring"
			/>
		</LegendCategories>
	);
};

const AnomalyType = () => {
	return (
		<>
			<LegendCategories>
				<LegendCategory
					fillColor="#4daf4a"
					outlineColor="#4daf4a"
					label="Anomálie nezjištěna"
				/>
				<LegendCategory
					fillColor="#984ea3"
					outlineColor="#984ea3"
					label="Opouštění, ponechání ladem"
				/>
				<LegendCategory
					fillColor="#ff7f00"
					outlineColor="#ff7f00"
					label="Jiné anomálie"
				/>
				<LegendCategory
					fillColor="#aaaaaa"
					outlineColor="#888888"
					label="DPB nesplňuje podmínky pro monitoring"
				/>
			</LegendCategories>
			<LegendCategories>
				<LegendCategoryLine
					color="#888888"
					width={4}
					label="Vyšší míra spolehlivosti"
				/>
				<LegendCategoryLine
					color="#888888"
					width={2}
					label="Nižší míra spolehlivosti"
				/>
			</LegendCategories>
		</>
	);
};

const AnomalySubtype = () => {
	return (
		<>
			<LegendCategories>
				<LegendCategory
					fillColor="#ff9100"
					outlineColor="#ff9100"
					label="Anomálie způsobené změnou kultury"
				/>
				<LegendCategory
					fillColor="#25b41a"
					outlineColor="#25b41a"
					label="Anomálie způsobené výskytem holé půdy (např. stavby a stavební činnosti)"
				/>
				<LegendCategory
					fillColor="#046fc6"
					outlineColor="#046fc6"
					label="Anomálie způsobené poklesem životaschopných jedinců "
				/>
				<LegendCategory
					fillColor="#ecd018"
					outlineColor="#ecd018"
					label="Anomálie způsobené vyšším výskytem chlorofylu "
				/>
				<LegendCategory
					fillColor="#c41a84"
					outlineColor="#c41a84"
					label="Anomálie způsobené vyklučením nebo dosadbou/obnovou"
				/>
				<LegendCategory
					fillColor="#7570b3"
					outlineColor="#7570b3"
					label="Více než jeden druh anomálie"
				/>
				<LegendCategory
					fillColor="#aaaaaa"
					outlineColor="#888888"
					label="DPB nesplňuje podmínky pro monitoring"
				/>
			</LegendCategories>
			<LegendCategories>
				<LegendCategoryLine
					color="#888888"
					width={4}
					label="Vyšší míra spolehlivosti"
				/>
				<LegendCategoryLine
					color="#888888"
					width={2}
					label="Nižší míra spolehlivosti"
				/>
			</LegendCategories>
		</>
	);
};

const Management = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#39bded"
				outlineColor="#39bded"
				label="Zjištěna seč"
			/>
			<LegendCategory
				fillColor="#94e312"
				outlineColor="#94e312"
				label="Zjištěna pastva"
			/>
			<LegendCategory
				fillColor="#f0c413"
				outlineColor="#f0c413"
				label="Zjištěn smíšený management"
			/>
			<LegendCategory
				fillColor="#c48e5e"
				outlineColor="#c48e5e"
				label="Nezjištěn žádný management"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="DPB nesplňuje podmínky pro monitoring"
			/>
		</LegendCategories>
	);
};

const NoMonitoringReason = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#ff7f00"
				outlineColor="#ff7f00"
				label="Geometrie DPB nesplňuje podmínky pro monitoring"
			/>
			<LegendCategory
				fillColor="#984ea3"
				outlineColor="#984ea3"
				label="Družicová data nejsou k dispozici nebo je nelze vyhodnotit"
			/>
			<LegendCategory
				fillColor="#e41a1c"
				outlineColor="#e41a1c"
				label="Ostatní důvody"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="DPB splňuje podmínky pro monitoring"
			/>
		</LegendCategories>
	);
};

const Outlines = () => {
	return (
		<LegendCategories>
			<LegendCategory outlineColor="#f0c85b" label="Hranice půdních bloků" />
		</LegendCategories>
	);
};

const Photos = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#e7298a"
				outlineColor="#e7298a"
				label="Ověřováno v terénu"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="Neověřováno v terénu"
			/>
		</LegendCategories>
	);
};

const Legend = ({name}) => {
	let content;

	switch (name) {
		case 'activityConfirmed':
			content = <ActivityConfirmed />;
			break;
		case 'activityType':
			content = <ActivityType />;
			break;
		case 'anomalySubtype':
			content = <AnomalySubtype />;
			break;
		case 'anomalyType':
			content = <AnomalyType />;
			break;
		case 'management':
			content = <Management />;
			break;
		case 'noMonitoringReason-kluceni':
		case 'noMonitoringReason-sece':
		case 'noMonitoringReason-anomalie':
			content = <NoMonitoringReason />;
			break;
		case 'outlines':
			content = <Outlines />;
			break;
		case 'photos':
			content = <Photos />;
			break;
		default:
			content = null;
	}

	return <div className="dromas-CaseStyle-legend">{content}</div>;
};

export default Legend;
