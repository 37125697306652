import photos from './points.json';

// photo capture layer
export const photoLayer = {
	key: 'photoLayer',
	name: 'Photo',
	type: 'vector',
	options: {
		features: photos.features,
		fidColumnName: 'fid',
		pointAsMarker: true,
		renderAs: [
			{
				options: {
					renderingTechnique: 'canvas',
					pointAsMarker: false,
					style: {
						rules: [
							{
								styles: [
									{
										fill: '#ee00fd',
										outlineWidth: 1,
										outlineColor: '#ee00fd',
										size: 50,
									},
								],
							},
						],
					},
				},
				boxRangeRange: [10000, null],
			},
			{
				boxRangeRange: [null, 10000],
			},
		],
		style: {
			rules: [
				{
					styles: [
						{
							fill: '#ee00fd',
							outlineWidth: 1,
							outlineColor: '#ffffff',
							size: 8,
						},
					],
				},
			],
		},
		selected: {
			points: {},
		},
	},
};
