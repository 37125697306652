import React from 'react';
import DonutChart from '../chartComponents/DonutChart';

const dataVinice = [
	{
		id: 'obnova',
		label: 'Obnova',
		value: 0.55,
		color: '#77cf29',
	},
	{
		id: 'kluceni',
		label: 'Klučení',
		value: 0.3,
		color: '#3e98f7',
	},
	{
		id: 'bezOperace',
		label: 'Bez operace',
		value: 0.15,
		color: '#be59de',
	},
];

const dataSady = [
	{
		id: 'obnova',
		label: 'Obnova',
		value: 0.38,
		color: '#77cf29',
	},
	{
		id: 'kluceni',
		label: 'Klučení',
		value: 0.27,
		color: '#3e98f7',
	},
	{
		id: 'bezOperace',
		label: 'Bez operace',
		value: 0.35,
		color: '#be59de',
	},
];

const dataViniceDec = [
	{
		id: 'obnova',
		label: 'Obnova',
		value: 0.06,
		color: '#77cf29',
	},
	{
		id: 'kluceni',
		label: 'Klučení',
		value: 0.04,
		color: '#3e98f7',
	},
	{
		id: 'bezOperace',
		label: 'Bez operace',
		value: 0.9,
		color: '#be59de',
	},
];

const dataSadyDec = [
	{
		id: 'obnova',
		label: 'Obnova',
		value: 0.08,
		color: '#77cf29',
	},
	{
		id: 'kluceni',
		label: 'Klučení',
		value: 0.05,
		color: '#3e98f7',
	},
	{
		id: 'bezOperace',
		label: 'Bez operace',
		value: 0.87,
		color: '#be59de',
	},
];

const ActivityType = () => {
	return (
		<>
			<h4>Druh operace</h4>
			<p>
				Distribuce DPB podle typu <em>deklarované operace</em>
			</p>
			<div className="dromas-Charts-section two-columns">
				<div>
					<h5>Vinice</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataViniceDec} legendItemWidth={70} />
					</div>
				</div>
				<div>
					<h5>Sady</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataSadyDec} legendItemWidth={70} />
					</div>
				</div>
			</div>
			<p>
				Distribuce DPB podle typu <em>nepotvrzené operace</em>
			</p>
			<div className="dromas-Charts-section two-columns">
				<div>
					<h5>Vinice</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataVinice} legendItemWidth={70} />
					</div>
				</div>
				<div>
					<h5>Sady</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataSady} legendItemWidth={70} />
					</div>
				</div>
			</div>
		</>
	);
};

export default ActivityType;
