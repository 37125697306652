import React from 'react';
import {ResponsivePie} from '@nivo/pie';
import ChartTooltip from '../ChartTooltip';

const getRowLegend = legendItemWidth => [
	{
		anchor: 'bottom',
		direction: 'row',
		justify: false,
		translateX: 0,
		translateY: 30,
		itemsSpacing: 15,
		itemWidth: legendItemWidth || 110,
		itemHeight: 15,
		itemTextColor: '#bbbbbb',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 15,
		symbolShape: 'circle',
	},
];

const getColumnLegend = (legendItemWidth, legendTranslateY) => [
	{
		anchor: 'bottom-left',
		direction: 'column',
		justify: false,
		translateX: 0,
		translateY: legendTranslateY || 50,
		itemsSpacing: 15,
		itemWidth: legendItemWidth || 100,
		itemHeight: 3,
		itemTextColor: '#bbbbbb',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 15,
		symbolShape: 'circle',
	},
];

const DonutChart = ({
	data,
	margin,
	legendItemWidth,
	legendTranslateY,
	columnLegend,
	format,
}) => {
	return (
		<ResponsivePie
			data={data}
			fontSize={30}
			colors={{datum: 'data.color'}}
			valueFormat={format || ' >-.0%'}
			margin={margin || {top: 15, right: 40, bottom: 40, left: 40}}
			innerRadius={0.4}
			padAngle={0.5}
			enableArcLinkLabels={false}
			activeOuterRadiusOffset={6}
			// arcLinkLabel="label"
			// arcLinkLabelsDiagonalLength={6}
			// arcLinkLabelsTextColor="#bbbbbb"
			// arcLinkLabelsThickness={2}
			// arcLinkLabelsColor={{from: 'color'}}
			// arcLinkLabelsStraightLength={10}
			arcLabelsTextColor={{from: 'color', modifiers: [['darker', 3]]}}
			arcLabelsSkipAngle={24}
			legends={
				columnLegend
					? getColumnLegend(legendItemWidth, legendTranslateY)
					: getRowLegend(legendItemWidth)
			}
			tooltip={ChartTooltip}
			theme={{
				fontSize: 13,
			}}
		/>
	);
};

export default DonutChart;
