import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		thematicLayer: Select.dromas.getThematicLayer(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		return {};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
