import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Actions from '../../../state/Action';
import Select from '../../../state/Select';
import {mapSetKey} from '../../../constants/app';

const mapStateToProps = (state, ownProps) => {
	const routerState = Select.router.getCurrent(state);
	const activeTab = routerState?.params?.parsedQueryString?.tabKey;

	return {
		activeTab: activeTab || null,
		activeCaseKey: Select.cases.getActiveKey(state),
		viewLimits: Select.maps.getMapSetViewLimits(state, mapSetKey),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		return {
			onTabClick: (evt, key) => {
				dispatch(Actions.dromas.updateAppUrl('case', {tabKey: key}));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
