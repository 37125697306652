import React from 'react';
import './style.scss';

const ChartTooltip = props => {
	const {datum, labelsDef} = props;

	if (datum) {
		const {formattedValue, label, color} = datum;
		return (
			<div className="dromas-ChartTooltip">
				<div
					className="dromas-ChartTooltip-color"
					style={{background: color}}
				/>
				<div className="dromas-ChartTooltip-label">{label}</div>
				<div className="dromas-ChartTooltip-value">{formattedValue}</div>
			</div>
		);
	} else if (labelsDef) {
		const {formattedValue, color, id} = props;
		const label = labelsDef[id];
		return (
			<div className="dromas-ChartTooltip">
				<div
					className="dromas-ChartTooltip-color"
					style={{background: color}}
				/>
				<div className="dromas-ChartTooltip-label">{label}</div>
				<div className="dromas-ChartTooltip-value">{formattedValue}</div>
			</div>
		);
	} else {
		return null;
	}
};

export default ChartTooltip;
