import React from 'react';
import DonutChart from '../chartComponents/DonutChart';

const dataCount = [
	{
		id: 'zmenaKultury',
		label: 'Změna kultury',
		value: 0.51,
		color: '#ff9100',
	},
	{
		id: 'vyskytHolePudy',
		label: 'Výskyt holé půdy',
		value: 0.11,
		color: '#25b41a',
	},
	{
		id: 'poklesJedincu',
		label: 'Pokles životaschopných jedinců',
		value: 0.19,
		color: '#046fc6',
	},
	{
		id: 'chlorofyl',
		label: 'Vyšší výskyt chlorofylu',
		value: 0.09,
		color: '#ecd018',
	},
	{
		id: 'dosadba',
		label: 'Neohlášené vyklučení, dosadba/obnova',
		value: 0.1,
		color: '#c41a84',
	},
];

const dataArea = [
	{
		id: 'zmenaKultury',
		label: 'Změna kultury',
		value: 0.45,
		color: '#ff9100',
	},
	{
		id: 'vyskytHolePudy',
		label: 'Výskyt holé půdy',
		value: 0.14,
		color: '#25b41a',
	},
	{
		id: 'poklesJedincu',
		label: 'Pokles životaschopných jedinců',
		value: 0.23,
		color: '#046fc6',
	},
	{
		id: 'chlorofyl',
		label: 'Vyšší výskyt chlorofylu',
		value: 0.06,
		color: '#ecd018',
	},
	{
		id: 'dosadba',
		label: 'Neohlášené vyklučení, dosadba/obnova',
		value: 0.12,
		color: '#c41a84',
	},
];

const AnomalySubtype = () => {
	return (
		<>
			<h4>Distribuce podle typu anomálie</h4>
			<div className="dromas-Charts-section two-columns">
				<div>
					<h5>Podíl podle počtu DPB</h5>
					<div className="dromas-ChartWrapper" style={{height: '16rem'}}>
						<DonutChart
							data={dataCount}
							columnLegend
							legendTranslateY={95}
							margin={{top: 15, right: 40, bottom: 105, left: 40}}
						/>
					</div>
				</div>
				<div>
					<h5>Podíl podle výměry DPB</h5>
					<div className="dromas-ChartWrapper" style={{height: '16rem'}}>
						<DonutChart
							data={dataArea}
							columnLegend
							legendTranslateY={95}
							margin={{top: 15, right: 40, bottom: 105, left: 40}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default AnomalySubtype;
