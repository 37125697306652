import queryString from 'query-string';

const getSubstate = state => state.router;

const getParsedQueryString = urlQueryString => {
	if (!urlQueryString) {
		return {};
	}
	const parsed = queryString.parse(urlQueryString);
	return parsed || {};
};

const getCurrent = state => {
	const substate = getSubstate(state);

	if (substate?.params?.queryString) {
		return {
			...substate,
			params: {
				...substate?.params,
				parsedQueryString: getParsedQueryString(substate.params.queryString),
			},
		};
	} else {
		return substate;
	}
};
export default {getCurrent};
