import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

const MapWrapper = ({
	activePathNameIsCase,
	activeStyleKeyFromUrl,

	defaultActivePeriodKey,
	activePeriodKeyFromUrl,
	activePeriodKeyFromState,
	setPeriodToURL,
	setActivePeriodKey,

	defaultActiveStyle,
	children,
	selectedFeatureKeyFromSelections,
	selectedFeatureKeyFromUrl,
	setFeatureToURL,
	setThematicLayerStyleKey,
	setThematicLayerStyleKeyToURL,
	zoomToFeatureGeometry,
	featureGeometry,
	addFeatureToSelection,
}) => {
	const savedSelectedFeatureKeyFromSelections = useRef(null);
	const savedSelectedFeatureKeyFromUrl = useRef(null);
	const savedActiveStyleKeyFromUrl = useRef(null);
	const savedActivePeriodKeyFromState = useRef(null);
	const savedActivePeriodKeyFromUrl = useRef(null);

	// track state selection and put featureId on change to the URL
	useEffect(() => {
		if (
			// selectedFeatureKeyFromSelections &&
			(!selectedFeatureKeyFromUrl ||
				selectedFeatureKeyFromUrl !== selectedFeatureKeyFromSelections) &&
			savedSelectedFeatureKeyFromSelections.current !==
				selectedFeatureKeyFromSelections
		) {
			savedSelectedFeatureKeyFromSelections.current =
				selectedFeatureKeyFromSelections;
			//nothing to change
			if (selectedFeatureKeyFromUrl != selectedFeatureKeyFromSelections) {
				setFeatureToURL(selectedFeatureKeyFromSelections);
			}
		}
	}, [selectedFeatureKeyFromSelections]);

	// Zoom to feature if featureID comes from URL
	useEffect(() => {
		if (
			featureGeometry &&
			savedSelectedFeatureKeyFromSelections.current !==
				selectedFeatureKeyFromUrl &&
			savedSelectedFeatureKeyFromUrl.current !== selectedFeatureKeyFromUrl
		) {
			savedSelectedFeatureKeyFromUrl.current = selectedFeatureKeyFromUrl;

			zoomToFeatureGeometry(featureGeometry);
			addFeatureToSelection(selectedFeatureKeyFromUrl);
		}
	}, [selectedFeatureKeyFromUrl, featureGeometry]);

	// Track active style from url to state
	useEffect(() => {
		if (
			activeStyleKeyFromUrl &&
			savedActiveStyleKeyFromUrl.current !== activeStyleKeyFromUrl
		) {
			savedActiveStyleKeyFromUrl.current = activeStyleKeyFromUrl;

			setThematicLayerStyleKey(activeStyleKeyFromUrl);
		}
	}, [activeStyleKeyFromUrl]);

	// Set default style key
	useEffect(() => {
		if (activePathNameIsCase && !activeStyleKeyFromUrl && defaultActiveStyle) {
			setThematicLayerStyleKeyToURL(defaultActiveStyle);
		}
	}, [activeStyleKeyFromUrl, defaultActiveStyle]);

	// track state active period key and put it on change to the URL
	useEffect(() => {
		if (
			activePeriodKeyFromState &&
			activePathNameIsCase &&
			(!activePeriodKeyFromUrl ||
				activePeriodKeyFromUrl !== activePeriodKeyFromState)
		) {
			savedActivePeriodKeyFromUrl.current = activePeriodKeyFromState;
			savedActivePeriodKeyFromState.current = activePeriodKeyFromState;
			setPeriodToURL(
				activePeriodKeyFromState,
				savedSelectedFeatureKeyFromSelections.current
			);
		}
	}, [activePathNameIsCase, activePeriodKeyFromUrl, activePeriodKeyFromState]);

	// Set active periodKey to state on chenge period in URL
	useEffect(() => {
		if (
			activePeriodKeyFromUrl &&
			savedActivePeriodKeyFromUrl.current !== activePeriodKeyFromUrl
		) {
			savedActivePeriodKeyFromUrl.current = activePeriodKeyFromUrl;

			setActivePeriodKey(activePeriodKeyFromUrl);
		}
	}, [activePeriodKeyFromUrl]);

	// Set default period if is not defined in url
	useEffect(() => {
		if (
			activePathNameIsCase &&
			!activePeriodKeyFromUrl &&
			defaultActivePeriodKey
		) {
			setActivePeriodKey(defaultActivePeriodKey);
		}
	}, [activePeriodKeyFromUrl, defaultActivePeriodKey]);

	return <>{children}</>;
};

MapWrapper.propTypes = {
	activePathNameIsCase: PropTypes.bool,
	activeStyleKeyFromUrl: PropTypes.string,
	defaultActiveStyle: PropTypes.string,
	children: PropTypes.element,
	selectedFeatureKeyFromSelections: PropTypes.string,
	selectedFeatureKeyFromUrl: PropTypes.string,
	setFeatureToURL: PropTypes.func,
	setThematicLayerStyleKey: PropTypes.func,
	zoomToFeatureGeometry: PropTypes.func,
	featureGeometry: PropTypes.object,
	addFeatureToSelection: PropTypes.func,
};

export default MapWrapper;
