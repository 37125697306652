import {isMatch as _isMatch, isPlainObject as _isPlainObject} from 'lodash';
import {Action as CommonAction, setRecomputeState} from '@gisatcz/ptr-state';
import {map as mapUtils} from '@gisatcz/ptr-utils';

import request from '../../utils/request';
import {getRouter} from '../../router';
import Action from '../Action';
import Select from '../Select';
import {photoLayer} from '../../data/photoLayer';
import {
	photoLayerKey,
	casesGetter,
	mapSetKey,
	appKey,
} from '../../constants/app';

import Actions from '../Action';
import ActionTypes from '../../constants/ActionTypes';

const zoomToFeatureGeometry = geometry => {
	return (dispatch, getState) => {
		let view = mapUtils.view.getViewFromGeometry(geometry, true);
		view.boxRange /= 2;
		const activeMapKey = Select.maps.getMapSetActiveMapKey(
			getState(),
			mapSetKey
		);
		dispatch(CommonAction.maps.updateMapAndSetView(activeMapKey, view));
	};
};

//TODO separate to router logic
const updateAppUrl = (name, update) => {
	return (dispatch, getState) => {
		const currentParams = Select.router.getCurrent(getState());

		const newParams = {
			...currentParams?.params?.path,
			...currentParams?.params?.parsedQueryString,
			...update,
		};

		// clear properties params with null or undefined value
		for (const property of Object.keys(newParams)) {
			if (!newParams[property]) {
				delete newParams[property];
			}
		}

		const router = getRouter();
		const url = router.pathFor(name, newParams);
		router.nav(url);
	};
};

const setActiveCaseByCaseUrl = caseUrl => {
	return (dispatch, getState) => {
		//load cases
		dispatch(
			Action.cases.useIndexed(
				casesGetter.filterByActive,
				casesGetter.filter,
				casesGetter.order,
				casesGetter.start,
				casesGetter.length,
				casesGetter.componentId
			)
		).then(() => {
			const caseByUrl = Select.dromas.getCaseByUrlPath(getState(), caseUrl);
			const caseKey = caseByUrl?.key;

			if (caseKey) {
				dispatch(Action.cases.setActiveKey(caseKey));

				// set active view
				const localConfiguration = Select.app.getCompleteConfiguration(
					getState()
				);
				const caseLinks = localConfiguration?.caseLinks || {};
				const caseLink = caseLinks[caseKey] || {};
				const caseViewKey = caseLink.viewKey;

				dispatch(Action.views.applyAndSetActive(caseViewKey, Action));
			}
		});
	};
};

// FIXME - should be ensured in timeline
function useRelationsForTimeline(start, length) {
	return (dispatch, getState) => {
		const state = getState();
		const layerTemplateKeys =
			Select.dromas.getTimelineLayerTemplates(state) || [];

		if (layerTemplateKeys?.length > 0) {
			layerTemplateKeys.flat().forEach(layerTemplateKey =>
				dispatch(
					CommonAction.data.spatialRelations.ensureIndexed(
						{
							applicationKey: appKey,
							layerTemplateKey,
						},
						null,
						start,
						length
					)
				)
			);
		}
	};
}

const togglePhotoLayer = visible => {
	return (dispatch, getState) => {
		const state = getState();
		const activeMapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
		if (visible) {
			dispatch(Action.maps.addMapLayers(activeMapKey, [photoLayer]));
		} else {
			dispatch(Action.maps.removeMapLayer(activeMapKey, photoLayerKey));
		}
	};
};

const selectPoint = (layerKey, featureKeys) => {
	return (dispatch, getState) => {
		const state = getState();
		const activeMap = Select.maps.getMapSetActiveMap(state, mapSetKey);
		const layer = Select.maps.getLayerStateByLayerKeyAndMapKey(
			state,
			activeMap.key,
			layerKey
		);
		if (layer) {
			dispatch(
				Action.maps.setMapLayerOption(activeMap.key, layerKey, 'selected', {
					points: {keys: featureKeys},
				})
			);
			dispatch(Action.selections.setActiveKey('points'));
			dispatch(
				Actions.selections.setActiveSelectionFeatureKeysFilterKeys(featureKeys)
			);
		}
	};
};

const setThematicLayerStyleKey = styleKey => {
	return (dispatch, getState) => {
		const state = getState();
		const thematicLayer = Select.dromas.getThematicLayer(state);
		const activeMapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);

		if (activeMapKey && thematicLayer) {
			dispatch(
				Action.maps.setMapLayerStyleKey(
					activeMapKey,
					thematicLayer.key,
					styleKey
				)
			);
			dispatch(Action.maps.use(activeMapKey));
		}
	};
};

const useMowingGrazingAttributes = componentKey => {
	return (dispatch, getState) => {
		const state = getState();
		setRecomputeState(state);

		const mowingGrazingAttributesRepository = Select.app.getConfiguration(
			state,
			'mowingGrazingAttributesRepository'
		);
		const activeCase = Select.cases.getActive(state);
		const dpbCode = Select.dromas.getSelectedFeatureDpbCode(componentKey);
		if (activeCase?.data?.nameInternal === 'case_sece-pastvy' && dpbCode) {
			const regex = /(\/)/g;
			const file = dpbCode.replace(regex, '_');

			// TODO for now, request allways for data
			const url = `${mowingGrazingAttributesRepository}${file}.json`;
			return request(url, 'GET', null, null).then(data => {
				if (data) {
					dispatch(actionAddMowingGrazingAttributesByDpbCode(data));
				}
			});
		}
	};
};

const useDataForDpbDetail = componentKey => {
	return (dispatch, getState) => {
		const state = getState();
		const filter = Select.dromas.getFilterForAttributesListUse(state);
		if (filter) {
			dispatch(
				CommonAction.data.components.updateComponent(componentKey, filter)
			);
			dispatch(CommonAction.data.components.use(componentKey)).then(() => {
				dispatch(useMowingGrazingAttributes(componentKey));
			});
		}
	};
};

const handleSelectionAfterTimelineClick = (layerTemplateKey, periodKey) => {
	return (dispatch, getState) => {
		const state = getState();
		const activeCase = Select.cases.getActiveKey(state);
		const caseLayerTemplateKey = Select.app.getConfiguration(
			state,
			`caseLinks.${activeCase}.layerTemplateKey`
		);

		// if there is active selection for polygons, clear it if thematic layer changed
		if (layerTemplateKey === caseLayerTemplateKey) {
			dispatch(Action.periods.setActiveKey(periodKey));
			dispatch(Action.selections.clearFeatureKeysFilter('polygonSelection'));
		}
	};
};

const actionAddMowingGrazingAttributesByDpbCode = data => {
	return {
		type: ActionTypes.ESADROMAS21.ADD_MOWING_GRAZING_ATTRIBUTES_BY_DPB_CODE,
		data,
	};
};

export default {
	updateAppUrl,
	selectPoint,
	setActiveCaseByCaseUrl,
	setThematicLayerStyleKey,
	togglePhotoLayer,
	useDataForDpbDetail,
	useRelationsForTimeline,
	handleSelectionAfterTimelineClick,
	zoomToFeatureGeometry,
};
