import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		name: Select.dromas.getLegendAndChartsNameForActiveStyle(state),
	};
};

export default connect(mapStateToProps)(Presentation);
