import React from 'react';

import kluceni from '../../../../../assets/img/cases/kluceni.jpg';
import sece from '../../../../../assets/img/cases/pastviny.jpg';
import anomalie from '../../../../../assets/img/cases/anomalie.jpg';

const CaseImage = ({className, aboutKey}) => {
	let src = null;
	switch (aboutKey) {
		case 'anomalie':
			src = anomalie;
			break;
		case 'sece':
			src = sece;
			break;
		case 'kluceni':
			src = kluceni;
			break;
	}

	return src ? <img className={className} alt="case_image" src={src} /> : null;
};

export default CaseImage;
