import React from 'react';
import DonutChart from '../chartComponents/DonutChart';
import StackedBarChart from '../chartComponents/StackedBarChart';

const dataCount = [
	{
		id: 'noMonitoring',
		label: 'Vyřazené DPB',
		value: 0.4,
		color: '#ff7f00',
	},
	{
		id: 'monitoring',
		label: 'Monitorované DPB',
		value: 0.6,
		color: '#aaaaaa',
	},
];

const dataArea = [
	{
		id: 'noMonitoring',
		label: 'Vyřazené DPB',
		value: 0.08,
		color: '#ff7f00',
	},
	{
		id: 'monitoring',
		label: 'Monitorované DPB',
		value: 0.92,
		color: '#aaaaaa',
	},
];

const colors = {
	monitoring: '#aaaaaa',
	noMonitoring: '#ff7f00',
};

const labels = {
	monitoring: 'Monitorované DPB',
	noMonitoring: 'Vyřazené DPB',
};

const dataSady = [
	{
		type: 'Počet DPB',
		monitoring: 0.94,
		noMonitoring: 0.06,
	},
	{
		type: 'Výměra DPB',
		monitoring: 0.99,
		noMonitoring: 0.01,
	},
];

const dataVinice = [
	{
		type: 'Počet DPB',
		monitoring: 0.51,
		noMonitoring: 0.49,
	},
	{
		type: 'Výměra DPB',
		monitoring: 0.89,
		noMonitoring: 0.11,
	},
];

const NoMonitoringReasonKluceni = () => {
	return (
		<>
			<h4>Rozložení monitorovaných a vyřazených DPB</h4>
			<div className="dromas-Charts-section two-columns">
				<div>
					<h5>Podíl podle počtu DPB</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataCount} />
					</div>
				</div>
				<div>
					<h5>Podíl podle výměry DPB</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataArea} />
					</div>
				</div>
			</div>
			<div className="dromas-Charts-section two-columns bar-charts">
				<div>
					<h5>Sady</h5>
					<div className="dromas-ChartWrapper">
						<StackedBarChart
							data={dataSady}
							keys={['monitoring', 'noMonitoring']}
							indexBy="type"
							colorsDef={colors}
							labelsDef={labels}
						/>
					</div>
				</div>
				<div>
					<h5>Vinice</h5>
					<div className="dromas-ChartWrapper">
						<StackedBarChart
							data={dataVinice}
							keys={['monitoring', 'noMonitoring']}
							indexBy="type"
							colorsDef={colors}
							labelsDef={labels}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default NoMonitoringReasonKluceni;
