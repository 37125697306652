import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {PantherSelect, PantherSelectItem} from '@gisatcz/ptr-atoms';
import CaseImage from './CaseImage';
import Logo from '../../../../Logo';
import About from './About';
import Footer from './Footer';

import './style.scss';

const getCaseByKey = (cases, caseKey) => {
	return caseKey ? Object.values(cases).find(c => c.key === caseKey) : null;
};

const getCaseByBEKey = (cases, caseKey) => {
	return caseKey ? Object.values(cases).find(c => c.beKey === caseKey) : null;
};

const CaseSelect = ({
	onUnmount,
	open,
	onCaseSelect,
	setRouterToRoot,
	activeCaseKey,
	cases = {},
	isOpen,
}) => {
	const [isInitialLoad, setInitialLoad] = useState(true);
	const [isCloseable, setCloseable] = useState(true);
	const activeCaseKeyRef = useRef();
	const activeCase = getCaseByBEKey(cases, activeCaseKey);

	useEffect(() => {
		if (activeCaseKeyRef.current !== activeCaseKey) {
			if (!activeCaseKey) {
				setCloseable(false);
			} else {
				setCloseable(true);
			}
			activeCaseKeyRef.current = activeCaseKey;
		}
	}, [activeCaseKey]);

	const renderCurrent = () => {
		if (activeCaseKey && activeCase) {
			return (
				<div className="dromas-CaseSelect-current-value">
					<Logo />
					<h2>{activeCase.data.nameDisplay}</h2>
				</div>
			);
		} else {
			//no indicator
			return (
				<span className="dromas-CaseSelect-current-placeholder">
					Select view...
				</span>
			);
		}
	};

	useEffect(() => {
		setInitialLoad(false);
		return onUnmount;
	}, []);

	const casesElms = [];

	for (const c of Object.values(cases)) {
		const ca = getCaseByKey(cases, c.key);
		casesElms.push(
			<PantherSelectItem
				className="dromas-CaseCard-wrapper"
				itemKey={ca.data.urlName}
				key={c.key}
			>
				<div className="dromas-CaseCard">
					<CaseImage
						className="dromas-CaseCard-image"
						aboutKey={c.data.aboutKey}
					/>
					<div className="dromas-CaseCard-title">
						<span>{c.data.description}</span>
						<h2>{c.data.nameDisplay}</h2>
					</div>
				</div>
			</PantherSelectItem>
		);
	}

	// TODO refactor onSelect, move to actions

	if (!isInitialLoad) {
		return (
			<PantherSelect
				className="dromas-CaseSelect"
				open={isOpen}
				onSelectClick={setRouterToRoot}
				onSelect={onCaseSelect}
				currentClasses="dromas-CaseSelect-current"
				renderCurrent={renderCurrent}
				listClasses="dromas-CaseSelect-list"
			>
				<div className="dromas-CaseSelect-content ptr-dark">
					<div className="dromas-Title">
						<Logo />
						<h1>Monitoring zemědělských plodin s využitím družicových dat</h1>
					</div>
					<div className="dromas-CaseCards">{casesElms}</div>
					<About />
					<Footer />
				</div>
			</PantherSelect>
		);
	} else {
		return null;
	}
};

CaseSelect.propTypes = {
	activeCaseKey: PropTypes.string,
	case: PropTypes.array,
	onCaseSelect: PropTypes.func,
	open: PropTypes.bool,
};

export default CaseSelect;
