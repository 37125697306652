import React from 'react';
import CaseStyle from './CaseStyle';
import Charts from './Charts';

import './style.scss';

const CaseOverview = ({thematicLayer}) => {
	return (
		<div className="dromas-CaseOverview">
			{thematicLayer ? <CaseStyle defaultActiveStyleIndex={0} /> : null}
			<Charts />
		</div>
	);
};

CaseOverview.propTypes = {};

export default CaseOverview;
