import React from 'react';
import CaseSelect from './CaseSelect';

import './style.scss';

const Header = ({isOpen}) => {
	return (
		<div className="dromas-Header ptr-dark">
			<CaseSelect isOpen={isOpen} />
		</div>
	);
};

export default Header;
