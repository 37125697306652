import React from 'react';
import {ResponsiveBar} from '@nivo/bar';
import ChartTooltip from '../ChartTooltip';

const DonutChart = ({data, keys, indexBy, colorsDef, labelsDef}) => {
	return (
		<ResponsiveBar
			data={data}
			keys={keys}
			indexBy={indexBy}
			margin={{top: 10, right: 0, bottom: 30, left: 0}}
			padding={0.35}
			valueFormat=" >-.0%"
			valueScale={{type: 'linear'}}
			indexScale={{type: 'band', round: true}}
			colors={bar => colorsDef[bar.id]}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 0,
				tickPadding: 7,
				tickRotation: 0,
			}}
			axisLeft={null}
			enableGridY={false}
			labelSkipHeight={13}
			labelTextColor={{from: 'color', modifiers: [['darker', 3]]}}
			tooltip={props =>
				React.createElement(ChartTooltip, {...props, labelsDef})
			}
			theme={{
				fontSize: 13,
				textColor: '#aaaaaa',
			}}
		/>
	);
};

export default DonutChart;
