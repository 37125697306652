import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';

import analytics from '../../../../utils/analytics';
import Presentation from './presentation';
import {mapSetKey, periodsGetter} from '../../../../constants/app';

const start = 1;
const length = 5000;

const mapStateToProps = (state, ownProps) => {
	const layers = Select.dromas.getTimelineLayers(state);
	const timelinePeriod = Select.dromas.getActiveCaseTimelinePeriodConfig(state);
	return {
		activeCaseKey: Select.cases.getActiveKey(state),
		layers,
		timelinePeriod,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			//ensure

			// FIXME - ensure in legend
			dispatch(
				Action.layerTemplates.useIndexed(
					periodsGetter.filterByActive,
					periodsGetter.filter,
					periodsGetter.order,
					periodsGetter.start,
					periodsGetter.length,
					periodsGetter.componentKey
				)
			);

			dispatch(Action.dromas.useRelationsForTimeline(start, length));
		},
		onLayerClick: (
			timelineLayerPeriodItem,
			timelineLayer,
			mapKey,
			layerRow
		) => {
			analytics.track('changeLayer', {
				layerRowTitle: layerRow?.legend?.title,
			});

			dispatch(
				Action.dromas.handleSelectionAfterTimelineClick(
					timelineLayer?.layerState?.layerTemplateKey,
					timelineLayerPeriodItem.origin.originPeriod.key
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
