import React, {useEffect, useRef} from 'react';
import {isNumber as _isNumber} from 'lodash';
import propTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import './style.scss';

const getTabsElements = (tabs = [], activeTabKey, onTabClick) => {
	return tabs.map(t => {
		return (
			<Button
				className={`ptr-panel-tap-btn${
					activeTabKey && activeTabKey === t.key ? ' active' : ''
				}`}
				key={t.key}
				onClick={evt => onTabClick(evt, t.key)}
			>
				<h3 className="ptr-button-caption">{t.title}</h3>
			</Button>
		);
	});
};

const SidePanel = ({
	activeTabKey,
	tabs = [],
	onTabClick,
	defaultActiveTabIndex,
}) => {
	const activeTab = activeTabKey && tabs.find(t => t.key === activeTabKey);

	useEffect(() => {
		if (!activeTab && _isNumber(defaultActiveTabIndex) && tabs?.length) {
			onTabClick(null, tabs[defaultActiveTabIndex].key);
		}
	}, []);

	const ActiveTabComponent = activeTab?.component || null;
	return (
		<div className="ptr-panel">
			<div className="ptr-panel-tabs">
				{getTabsElements(tabs, activeTabKey, onTabClick)}
			</div>
			<div className="ptr-panel-tab-content">
				{ActiveTabComponent ? <ActiveTabComponent /> : null}
			</div>
		</div>
	);
};

SidePanel.propTypes = {
	activeTabKey: propTypes.string,
	tabs: propTypes.array,
	onTabClick: propTypes.func,
};

export default SidePanel;
