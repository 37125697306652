import ActionTypes from '../../constants/ActionTypes';

const INITIAL_STATE = {
	mowingGrazingAttributesByDpbCode: {},
};

const addMowingGrazingAttributesByDpbCode = (state, record) => {
	const {NKOD_DPB, data} = record;
	return {
		...state,
		mowingGrazingAttributesByDpbCode: {
			...state.mowingGrazingAttributesByDpbCode,
			[NKOD_DPB]: data,
		},
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.ESADROMAS21.ADD_MOWING_GRAZING_ATTRIBUTES_BY_DPB_CODE:
			return addMowingGrazingAttributesByDpbCode(state, action.data);
		default:
			return state;
	}
};
