import moment from 'moment';
import {createSelector} from 'reselect';
import {period as periodParser} from '@gisatcz/ptr-utils';
import {
	find as _find,
	flatten as _flatten,
	filter as _filter,
	isMatch as _isMatch,
	isEmpty as _isEmpty,
} from 'lodash';
import {
	Select,
	createRecomputeSelector,
	createRecomputeObserver,
} from '@gisatcz/ptr-state';

import routerSelectors from '../router/selectors';

import {
	casesGetter,
	cases as casesConfigs,
	mapSetKey,
	appKey,
	compositeLayerTemplateKey,
	orthophotoWestLayerTemplateKey,
	orthophotoEastLayerTemplateKey,
	timelineLayerLineHeight,
	timelineLayerElementHeight,
} from '../../constants/app';

import points from '../../data/points.json';

const basicTimelineColor = 'var(--accent25)';
const activeTimelineColor = 'var(--accent65)';

const getActiveCaseStyleKeys = createSelector(
	[Select.cases.getActiveKey, Select.app.getCompleteConfiguration],
	(activeCaseKey, configuration) => {
		const activeLayerTemplateKey =
			configuration?.caseLinks?.[activeCaseKey]?.layerTemplateKey;

		if (activeLayerTemplateKey) {
			const styleKeys =
				configuration?.stylesByLayerTemplateKey[activeLayerTemplateKey];
			if (styleKeys) {
				return styleKeys;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getStylesForActiveCase = createSelector(
	[Select.styles.getAllAsObject, getActiveCaseStyleKeys],
	(styles, styleKeys) => {
		if (styles && styleKeys) {
			let models = [];
			styleKeys.forEach(key => {
				if (styles[key]) {
					models.push(styles[key]);
				}
			});
			return models.length ? models : null;
		} else {
			return null;
		}
	}
);

const getThematicLayer = createSelector(
	[
		state => Select.maps.getMapSetActiveMap(state, mapSetKey),
		Select.cases.getActiveKey,
		Select.app.getCompleteConfiguration,
	],
	(activeMap, activeCaseKey, configuration) => {
		const activeLayerTemplateKey =
			configuration?.caseLinks?.[activeCaseKey]?.layerTemplateKey;
		if (activeLayerTemplateKey && activeMap) {
			const layer = _find(
				activeMap.data?.layers,
				layer => layer.layerTemplateKey === activeLayerTemplateKey
			);
			return layer || null;
		} else {
			return null;
		}
	}
);

const getSelectedFeatureKey = createSelector(
	[Select.selections.getAllAsObject],
	selections => {
		const selection = selections?.['polygonSelection'];
		if (selection) {
			return selection.data?.featureKeysFilter?.keys?.[0] || null;
		} else {
			return null;
		}
	}
);

const getSelectedFeatureKeyByRouter = createSelector(
	[routerSelectors.getCurrent],
	router => {
		return router?.params?.parsedQueryString?.featureId || null;
	}
);

const getThematicLayerStyle = createSelector(
	[getThematicLayer, Select.styles.getAllAsObject],
	(thematicLayer, styles) => {
		if (styles && thematicLayer) {
			const styleKey = thematicLayer?.styleKey;
			if (styleKey) {
				return styles[styleKey] || null;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getLegendAndChartsNameForActiveStyle = createSelector(
	[
		getThematicLayerStyle,
		Select.cases.getActiveKey,
		state => Select.app.getConfiguration(state, 'caseLinks'),
	],
	(style, activeCaseKey, caseLinks) => {
		if (style && activeCaseKey && caseLinks) {
			return caseLinks[activeCaseKey]?.legendAndChartsByStyleKey?.[style.key];
		} else {
			return null;
		}
	}
);

const getDefaultActiveStyle = createSelector(
	[getActiveCaseStyleKeys],
	activeCaseStyleKeys => {
		if (activeCaseStyleKeys?.length > 0) {
			return activeCaseStyleKeys[0];
		} else {
			return null;
		}
	}
);

const getDefaultActivePeriodKey = createSelector(
	[Select.cases.getActiveKey, Select.app.getCompleteConfiguration],
	(activeCaseKey, configuration) => {
		const defaultActivePeriodKey =
			configuration?.caseLinks?.[activeCaseKey]?.defaultPeriodKey;

		if (defaultActivePeriodKey) {
			return defaultActivePeriodKey;
		} else {
			return null;
		}
	}
);

const getActiveCaseAboutKey = createSelector(
	[
		Select.cases.getActiveKey,
		state => Select.app.getConfiguration(state, 'caseLinks'),
	],
	(activeCaseKey, caseLinks) => {
		if (activeCaseKey && caseLinks) {
			const data = caseLinks[activeCaseKey];
			return data?.aboutKey;
		} else {
			return null;
		}
	}
);

const getFilterForAttributesListUse = createSelector(
	[
		getSelectedFeatureKeyByRouter,
		Select.app.getCompleteConfiguration,
		Select.attributeSets.getAll,
		Select.cases.getActiveKey,
		Select.periods.getActiveKey,
	],
	(featureKey, configuration, attributeSets, caseKey, periodKey) => {
		if (configuration && featureKey && attributeSets && caseKey) {
			const layerTemplateKey =
				configuration?.caseLinks?.[caseKey]?.layerTemplateKey;
			const attributeKeysCollections = attributeSets.map(
				set => set.data.attributes
			);
			const attributeKeys = _flatten(attributeKeysCollections);
			if (attributeKeys?.length && layerTemplateKey) {
				return {
					metadataModifiers: {
						caseKey,
						applicationKey: appKey,
						periodKey: periodKey,
					},
					layerTemplateKey,
					attributeKeys,
					featureKeys: [featureKey],
					start: 1,
					length: 1,
				};
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getAttributesAsObjectObserver = createRecomputeObserver(
	Select.attributes.getAllAsObject
);
const getAttributeSetsObserver = createRecomputeObserver(
	Select.attributeSets.getAll
);

const getSelectedFeatureKeyByRouterObserver = createRecomputeObserver(
	getSelectedFeatureKeyByRouter
);

const getAttributesGroupedBySet = createRecomputeSelector(componentKey => {
	const attributesAsObject = getAttributesAsObjectObserver();
	const attributeSets = getAttributeSetsObserver();
	const selectedFeatureKey = getSelectedFeatureKeyByRouterObserver();
	const data = Select.data.components.getData(componentKey);

	const featureData = data?.[0];
	if (
		attributeSets &&
		attributesAsObject &&
		featureData &&
		selectedFeatureKey
	) {
		const {key, data: properties} = featureData;

		const attributeSetsData = [];
		attributeSets.forEach(attributeSet => {
			const attributesData = [];
			const attributeKeys = attributeSet?.data?.attributes;

			if (attributeKeys) {
				attributeKeys.forEach(key => {
					const attributeMetadata = attributesAsObject[key];
					if (attributeMetadata) {
						const value = properties[key];
						if (value !== undefined) {
							attributesData.push({
								metadata: attributeMetadata,
								value,
							});
						}
					}
				});
			}

			if (attributesData?.length) {
				attributeSetsData.push({
					metadata: attributeSet,
					attributes: attributesData,
				});
			}
		});

		return attributeSetsData?.length ? attributeSetsData : null;
	} else {
		return null;
	}
});

const getCompleteConfigurationObserver = createRecomputeObserver(
	Select.app.getCompleteConfiguration
);

const getSelectedFeatureDpbCode = createRecomputeSelector(componentKey => {
	const configuration = getCompleteConfigurationObserver();
	const attributeData = Select.data.components.getData(componentKey);
	const selectedFeatureData = attributeData?.[0];

	if (selectedFeatureData && configuration) {
		const {dpbCodeAttributeKey} = configuration;
		const {data: selectedFeatureProperties} = selectedFeatureData;

		if (selectedFeatureProperties && dpbCodeAttributeKey) {
			return selectedFeatureProperties[dpbCodeAttributeKey];
		} else {
			return null;
		}
	} else {
		return null;
	}
});

const getPhotosForSelectedDpb = createRecomputeSelector(componentKey => {
	const configuration = getCompleteConfigurationObserver();
	const photoPoints = points.features;

	if (photoPoints && configuration) {
		const {photoThumbnailsUrlPath, photosUrlPath} = configuration;
		const dpbCode = getSelectedFeatureDpbCode(componentKey);
		if (dpbCode) {
			const photoItems = _filter(
				photoPoints,
				point => point.properties?.['NKOD_DPB'] === dpbCode
			);
			if (photoItems?.length) {
				return photoItems.map(item => {
					const {properties} = item;
					const date = properties.Date;
					return {
						id: properties.fid,
						date: properties.Date,
						fullscreen: `${photosUrlPath}${properties.Name}`,
						thumbnail: `${photoThumbnailsUrlPath}${properties.thumb}`,
						original: `${photoThumbnailsUrlPath}${properties.thumb}`,
						name: properties.NKOD_DPB,
						description: moment(date).format('D. M. YYYY'),
					};
				});
			} else {
				return null;
			}
		} else {
			return null;
		}
	} else {
		return null;
	}
});

const getActiveCaseObserver = createRecomputeObserver(Select.cases.getActive);

const getMowingGrazingAttributesByDpbCodeObserver = createRecomputeObserver(
	state => state.dromas.mowingGrazingAttributesByDpbCode
);

const getMowingGrazingAttributes = createRecomputeSelector(componentKey => {
	const activeCase = getActiveCaseObserver();
	const dpbCode = getSelectedFeatureDpbCode(componentKey);
	const mowingGrazingAttributesByDpbCode =
		getMowingGrazingAttributesByDpbCodeObserver();
	const data = mowingGrazingAttributesByDpbCode?.[dpbCode];

	if (activeCase?.data?.nameInternal === 'case_sece-pastvy' && data) {
		return data;
	} else {
		return null;
	}
});

// TODO refactor following selectors
const getCasesForSelect = state => {
	const cases =
		Select.cases.getIndexed(
			state,
			casesGetter.filterByActive,
			casesGetter.filter,
			casesGetter.order,
			casesGetter.start,
			casesGetter.length
		) || [];
	const localConfiguration = Select.app.getCompleteConfiguration(state);
	const casesForSelect = {};
	const caseLinks = localConfiguration?.caseLinks || {};
	const caseGroups = localConfiguration?.caseGroups || {};

	for (const caseConfig of Object.values(casesConfigs)) {
		const c = cases.find(x => x.data.nameInternal === caseConfig.key);
		const caseLink = caseLinks[c?.key] || {};
		casesForSelect[caseConfig.key] = {
			...caseConfig,
			beKey: c?.key,
			data: {
				...caseConfig.data,
				aboutKey: caseLink.aboutKey,
				nameDisplay: c?.data?.nameDisplay,
				description: caseGroups[caseLink?.caseGroupKey]?.name,
			},
		};
	}
	return casesForSelect;
};

const getCaseByUrlPath = (state, urlPath) => {
	const cases =
		Select.cases.getIndexed(
			state,
			casesGetter.filterByActive,
			casesGetter.filter,
			casesGetter.order,
			casesGetter.start,
			casesGetter.length
		) || [];
	const caseConfig = Object.values(casesConfigs).find(
		c => c.data.urlName === urlPath
	);
	const caseConfidKey = caseConfig?.key;
	return cases.find(c => c.data.nameInternal === caseConfidKey) || null;
};

const getTimelineLayerTemplates = createSelector(
	[Select.app.getCompleteConfiguration, Select.cases.getActiveKey],
	(localConfiguration, activeCase) => {
		const timelineLayerKeys = localConfiguration?.timelineLayerKeys || [];
		const caseLinks = localConfiguration?.caseLinks?.[activeCase];
		const caseLayerTemplateKey = caseLinks?.layerTemplateKey;

		return [...timelineLayerKeys, caseLayerTemplateKey].filter(i => i);
	}
);

const getThematicLayerTimelineConfig = createSelector(
	[
		Select.app.getCompleteConfiguration,
		Select.cases.getActiveKey,
		getThematicLayer,
	],
	(localConfiguration, activeCaseKey, thematicLayer) => {
		let config = null;
		if (localConfiguration && activeCaseKey && thematicLayer) {
			// const layerName = localConfiguration.caseLinks[activeCaseKey].name;
			const layerName = 'Vrstva DPB';
			const layerTemplateKey =
				localConfiguration.caseLinks[activeCaseKey].layerTemplateKey;

			const mapZIndex = 3;

			config = {
				lineHeight: timelineLayerLineHeight,
				elementHeight: timelineLayerElementHeight,
				legend: {
					title: layerName,
				},
				items: [
					{
						periods: {
							filter: {
								layerTemplateKey,
							},
							filterByActive: {
								application: true,
							},
						},
						colors: {
							basic: basicTimelineColor, //should be on row
							active: activeTimelineColor, //should be on row
						},
						states: ['basic', 'active', 'hover', 'disabled'],
						activeStates: ['basic'],
						mapZIndex: mapZIndex,
						layerState: {
							layerTemplateKey,
							styleKey: thematicLayer.styleKey,
							metadataModifiers: {
								...thematicLayer.metadataModifiers,
							},
							filterByActive: {
								...thematicLayer.filterByActive,
							},
							options: {
								...thematicLayer.options,
							},
						},
					},
				],
				controlMapState: 'toggle',
				allowNonActiveLayer: false,
			};
		}
		return config;
	}
);

const getOrthophotosTimelineConfig = createSelector(
	[Select.app.getCompleteConfiguration],
	localConfiguration => {
		let config = null;
		if (localConfiguration) {
			const layerName = 'Ortofoto';

			const mapZIndex = 1;

			config = {
				lineHeight: timelineLayerLineHeight,
				elementHeight: timelineLayerElementHeight,
				legend: {
					title: layerName,
				},
				items: [
					{
						periods: {
							filter: {
								layerTemplateKey: orthophotoWestLayerTemplateKey,
							},
							filterByActive: {
								application: true,
							},
						},
						colors: {
							basic: basicTimelineColor, //should be on row
							active: activeTimelineColor, //should be on row
						},
						states: ['basic', 'active', 'hover', 'disabled'],
						activeStates: ['basic'],
						mapZIndex,
						layerState: {
							layerTemplateKey: orthophotoWestLayerTemplateKey,
						},
					},
					{
						periods: {
							filter: {
								layerTemplateKey: orthophotoEastLayerTemplateKey,
							},
							filterByActive: {
								application: true,
							},
						},
						colors: {
							basic: basicTimelineColor, //should be on row
							active: activeTimelineColor, //should be on row
						},
						states: ['basic', 'active', 'hover', 'disabled'],
						activeStates: ['basic'],
						mapZIndex,
						layerState: {
							layerTemplateKey: orthophotoEastLayerTemplateKey,
						},
					},
				],
				controlMapState: 'toggle',
			};
		}
		return config;
	}
);

const getLPISOrthophotTimelineConfig = createSelector(
	[Select.app.getCompleteConfiguration],
	localConfiguration => {
		let config = null;
		if (localConfiguration) {
			const mapZIndex = 2;

			config = {
				lineHeight: timelineLayerLineHeight,
				elementHeight: timelineLayerElementHeight,
				items: [
					{
						periods: [
							{
								key: '486d4dfe-0ab9-42bc-8381-590854b414cb',
							},
						],
						colors: {
							basic: basicTimelineColor, //should be on row
							active: activeTimelineColor, //should be on row
						},
						states: ['basic', 'active', 'hover', 'disabled'], //should be on row
						activeStates: ['basic'], //should be on period
						mapZIndex,
						layerState: {
							layerTemplateKey: '18303ea3-bff3-4efa-bc83-4880f78e5276',
							filterByActive: {
								application: true,
							},
						},
					},
				],
				controlMapState: 'toggle',
				legend: {
					title: 'Ortofoto LPIS',
				},
			};
		}
		return config;
	}
);

const getCompositeTimelineConfig = createSelector(
	[Select.app.getCompleteConfiguration],
	localConfiguration => {
		let config = null;
		if (localConfiguration) {
			const mapZIndex = 0;

			config = {
				lineHeight: timelineLayerLineHeight,
				elementHeight: timelineLayerElementHeight,
				legend: {
					title: 'Měsíční mozaiky S2',
				},
				items: [
					{
						periods: {
							//filter
							filter: {
								layerTemplateKey: compositeLayerTemplateKey,
							},
							filterByActive: {
								application: true,
							},
						},
						colors: {
							basic: basicTimelineColor, //should be on row
							active: activeTimelineColor, //should be on row
						},
						states: ['basic', 'active', 'hover', 'disabled'],
						activeStates: ['basic'],
						mapZIndex,
						layerState: {
							layerTemplateKey: compositeLayerTemplateKey,
							filterByActive: {
								application: true,
							},
						},
					},
				],
				controlMapState: 'toggle',
			};
		}
		return config;
	}
);

const getActiveCaseTimelineLayersConfig = createSelector(
	[Select.app.getCompleteConfiguration, Select.cases.getActiveKey],
	(configuration, activeCaseKey) => {
		if (configuration && activeCaseKey) {
			const caseLinks = configuration.caseLinks[activeCaseKey];
			return caseLinks?.timeline?.layers || null;
		} else {
			return null;
		}
	}
);

const getActiveCaseTimelinePeriodConfig = createSelector(
	[Select.app.getCompleteConfiguration, Select.cases.getActiveKey],
	(configuration, activeCaseKey) => {
		if (configuration && activeCaseKey) {
			const caseLinks = configuration.caseLinks[activeCaseKey];
			if (caseLinks?.timeline?.period) {
				const parsed = periodParser.parse(caseLinks.timeline.period);
				return {
					start: parsed.start.toString(),
					end: parsed.end.toString(),
				};
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const filterTimelineConfigRowsByLayerTemplateKeys = (
	timelineRowConfig,
	layerTemplatesKeys
) => {
	if (timelineRowConfig && layerTemplatesKeys?.length > 0) {
		const tmpTimelineRowConfig = _.cloneDeep(timelineRowConfig);
		tmpTimelineRowConfig.items = tmpTimelineRowConfig?.items?.filter(item => {
			const layerInlayerTemplatesKeys = layerTemplatesKeys.find(ltKey => {
				if (ltKey?.length > 0) {
					return ltKey.includes(item?.layerState?.layerTemplateKey);
				} else {
					return ltKey === item?.layerState?.layerTemplateKey;
				}
			});
			return !!layerInlayerTemplatesKeys;
		});

		if (tmpTimelineRowConfig?.items?.length > 0) {
			return tmpTimelineRowConfig;
		} else {
			return null;
		}
	} else {
		return null;
	}
};
const getTimelineLayers = createSelector(
	[
		getThematicLayerTimelineConfig,
		getOrthophotosTimelineConfig,
		getLPISOrthophotTimelineConfig,
		getCompositeTimelineConfig,
		getActiveCaseTimelineLayersConfig,
	],
	(
		thematicLayerTimelineConfig,
		orthophotosLayersTimelineConfig,
		LPISorthophotoLayersTimelineConfig,
		compositeLayersTimelineConfig,
		activeCaseTimelineConfig
	) => {
		const filteredOrthophotosLayersTimelineConfig =
			filterTimelineConfigRowsByLayerTemplateKeys(
				orthophotosLayersTimelineConfig,
				activeCaseTimelineConfig
			);
		const filteredLPISorthophotoLayersTimelineConfig =
			filterTimelineConfigRowsByLayerTemplateKeys(
				LPISorthophotoLayersTimelineConfig,
				activeCaseTimelineConfig
			);
		const filteredCompositeLayersTimelineConfig =
			filterTimelineConfigRowsByLayerTemplateKeys(
				compositeLayersTimelineConfig,
				activeCaseTimelineConfig
			);

		const timelineLayers = [
			...(thematicLayerTimelineConfig ? [thematicLayerTimelineConfig] : []),
			...(filteredLPISorthophotoLayersTimelineConfig
				? [filteredLPISorthophotoLayersTimelineConfig]
				: []),
			...(filteredOrthophotosLayersTimelineConfig
				? [filteredOrthophotosLayersTimelineConfig]
				: []),
			...(filteredCompositeLayersTimelineConfig
				? [filteredCompositeLayersTimelineConfig]
				: []),
		];

		return timelineLayers;
	}
);

const getFeatureGeometryFromThematicLayerById = createSelector(
	[
		Select.app.getCompleteConfiguration,
		Select.cases.getActiveKey,
		Select.periods.getActiveKey,
		state => state,
		(state, featureId) => featureId,
	],
	(configuration, caseKey, periodKey, state, featureId) => {
		const order = null;
		const layerTemplateKey =
			configuration?.caseLinks?.[caseKey]?.layerTemplateKey;

		const filter = {
			layerTemplateKey,
			modifiers: {
				caseKey,
				periodKey,
				applicationKey: appKey,
			},
		};
		const index = Select.data.spatialData.getIndex(state, filter, order);
		const spatialDataSourceKey = index?.index
			? Object.keys(index?.index)[0]
			: null;

		const byDataSourceKey = spatialDataSourceKey
			? Select.data.spatialData.getByDataSourceKeyObserver(spatialDataSourceKey)
			: null;

		if (byDataSourceKey && byDataSourceKey[featureId]?.geometry) {
			return byDataSourceKey[featureId].geometry;
		}
	}
);

export default {
	getTimelineLayers,
	getActiveCaseAboutKey,
	getActiveCaseStyleKeys,
	getAttributesGroupedBySet,
	getCasesForSelect,
	getCaseByUrlPath,
	getFilterForAttributesListUse,
	getLegendAndChartsNameForActiveStyle,
	getMowingGrazingAttributes,
	getPhotosForSelectedDpb,
	getSelectedFeatureKey,
	getSelectedFeatureDpbCode,
	getStylesForActiveCase,
	getThematicLayer,
	getThematicLayerStyle,
	getActiveCaseTimelinePeriodConfig,
	getTimelineLayerTemplates,
	getFeatureGeometryFromThematicLayerById,
	getDefaultActiveStyle,
	getDefaultActivePeriodKey,
};
