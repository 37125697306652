import React from 'react';
import propTypes from 'prop-types';

import './style.scss';

const CaseDescription = ({caseName}) => {
	switch (caseName) {
		case 'anomalie':
			return (
				<>
					<h4>Monitoring nežádoucích jevů (anomálií) pro trvalé kultury</h4>
					<p>
						Cílem této pilotní studie je ověřit možnost identifikace anomálií na
						porostech trvalých kultur (vinice, chmelnice a ovocné sady). Pomocí
						analýzy časových řad družicových snímků Sentinel byla provedena
						detekce DPB, které vykazují nestandardní stav nebo vývoj vegetačního
						porostu.
					</p>
					<p>
						Anomálie jsou rozděleny podle příčiny výskytu anomálie do dvou
						základních skupin:
					</p>
					<h5>Skupina č. 1 - Opuštěné pozemky nebo pozemky ponechamé ladem</h5>
					<p>
						Pozemek vykazuje známky přerostlé vegetace, větve nejsou pravidelně
						prořezávané, vytváří husté koruny. Okolní vegetace je bez zásahu,
						byliny a trávy přerůstají keře, menší stromky. Na celém pozemku je
						detekovatelné velké množství biomasy po celou vegetační sezónu.
					</p>
					<h5>Skupina č. 2 - Anomálie způsobené jinými vlivy</h5>
					<p>
						Tato skupiny zahrnuje celou řadu případů, zejména následující
						kategorie:
					</p>
					<ul>
						<li>Anomálie způsobené změnou kultury</li>
						<li>
							Anomálie způsobené výskytem staveb a stavebních činností (budova,
							silnice aj.)
						</li>
						<li>
							Anomálie způsobené absencí chlorofylu (hnojiště, stoh, půda bez
							vegetace aj.)
						</li>
						<li>
							Anomálie způsobené poklesem životaschopných jedinců (úhyn
							rostlinek/keřů/stromů)
						</li>
						<li>
							Anomálie způsobené vyšším výskytem chlorofylu (neudržované
							meziřadí, neudržovaný příkmenný pás aj.)
						</li>
						<li>
							Anomálie způsobené neohlášeným vyklučením nebo dosadbou/obnovou
							porostu{' '}
						</li>
					</ul>
					<p>
						Ověření výskytu anomálií bylo provedeno pro všechny DPB s kulturou
						V/C/S na celém území Česka pro rok 2021.{' '}
					</p>
				</>
			);
		case 'kluceni':
			return (
				<>
					<h4>Ověření průběhu klučení a obnovy vinic a ovocných sadů</h4>
					<p>
						Tato pilotní studie se zaměřuje na pozemky, u kterých žadatatelé
						ohlásili klučení nebo obnovu porostů vinic a ovocných sadů. Jejím
						cílem je ověřit, zda pomocí družicových dat je možné zkontrolovat,
						zda ohlášené operace probíhají podle plánovaného harmonogramu.{' '}
					</p>
					<p>
						K vyklučení může dojít na porostech vinic a ovocných sadů. Vyklučení
						znamená úplné odstranění stromů/keřů i s jejich kořenovým systémem,
						buď s cílem přeměny kultury (nutná změna kultury v LPIS) nebo s
						cílem vysadit nové stromy/keře bez nutnosti měnit kulturu v LPIS.
						Operace klučení může být realizována na celé ploše DPB nebo jen jeho
						části.
					</p>
					<p>
						K obnově (založení/dosadbě/výsadbě) sadu nebo vinice může dojít po
						vyklučení původních keřů/stromků nebo v případě, že došlo ke změně
						kultury a založení nového porostu na pozemku, na kterém klučení
						neproběhlo. Obnova může probíhat na celé ploše DPB nebo jen jeho
						části.
					</p>
					<p>
						Ověření průběhu klučení a obnovy vinic a ovocných sadů bylo
						provedeno pro všechny DPB v režimu AEKO
						(agroenvironmentálně-klimatické opatření), na kterých byly
						kontrolované operace ohlášeny v letech 2018 - 2021.
					</p>
				</>
			);
		case 'sece':
			return (
				<>
					<h4>Monitoring seče a pastvy na travních porostech</h4>
					<p>
						Cílem této aktivity bylo dále rozvinout metodu detekce sečí a pastvy
						na pozemcích s travním porostem. Součástí byl i požadavek ověřit
						přesnost odlišení seče a pastvy a možnost identifikace pozemků, na
						kterých probíhají tyto operace současně a/nebo pouze na části celé
						vyměry DPB. Vzhledem požadavku na dloudodobý a průběžný monitoring
						jsou předmětem analýzy zejména časové profily vybraných indikátorů
						odvozených z družicových dat Sentinel.
					</p>
					<p>
						Trvalé travní porosty je možné charakterizovat jako stanoviště, kde
						dominují trávy nad keři a stromy. Jejich rozdělení na louky a
						pastviny je dáno rozdílným způsobem obhospodařování (kosení,
						pastva), tato informace však není součástí LPIS ani není uváděna
						žadatelem v dotační žádosti.
					</p>
					<p>
						Ověření monitoringu proběhlo na téměř 7 tisíc pozemcích ve třech
						pilotních oblastech vybraných SZIF. Výsledky byly připraveny v
						podobě průběžných měsíčních odevzdávek od června do října 2021.
					</p>
				</>
			);
		default:
			return null;
	}
};

CaseDescription.propTypes = {
	caseName: propTypes.string,
};

const CaseAbout = ({activeAboutKey}) => {
	return (
		<div className="dromas-CaseAbout">
			<CaseDescription caseName={activeAboutKey} />
		</div>
	);
};

CaseAbout.propTypes = {
	activeAboutKey: propTypes.string,
};

export default CaseAbout;
