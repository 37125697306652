import {utils} from '@gisatcz/ptr-utils';

export const appKey = 'esaDromas21';

export const mapSetKey = 'map-set';

export const gaId = 'UA-59584085-16';

export const photoLayerKey = 'photoLayer';

export const timelineLayerLineHeight = 16; //px

export const timelineLayerElementHeight = 10; //px, rest is for line padding

export const compositeLayerTemplateKey = '35908a0f-c769-48ba-ae67-efbb101a15e5';
export const orthophotoEastLayerTemplateKey =
	'7e62fe5d-a43b-4af8-8f30-f2c788b5be42';
export const orthophotoWestLayerTemplateKey =
	'17e9aa37-8b98-4918-82e6-3bcf5b528372';

export const cases = {
	'kluceni-obnova': {
		key: 'case_kluceni-obnova',
		data: {
			urlName: 'kluceni-obnova',
		},
	},
	anomalie: {
		key: 'case_anomalie',
		data: {
			urlName: 'anomalie',
		},
	},
	'sece-pastvy': {
		key: 'case_sece-pastvy',
		data: {
			urlName: 'sece-pastvy',
		},
	},
};

export const casesGetter = {
	filterByActive: {
		application: true,
	},
	filter: {},
	order: null,
	start: 1,
	length: 10,
	componentId: `appCases_${utils.uuid()}`,
};

export const periodsGetter = {
	filterByActive: {
		application: true,
	},
	filter: {},
	order: null,
	start: 1,
	length: 1000,
	componentId: `appPeriods_${utils.uuid()}`,
};
