import {connect} from '@gisatcz/ptr-state';
import Select from '../../state/Select';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		page: Select.router.getCurrent(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		return {};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
