import React from 'react';

export default {
	cartoDB_Light: {
		key: 'cartoDB_Light',
		thumbnail: 'cartoDB_VoyagerNoLabels',
		name: 'Základní',
		type: 'wmts',
		options: {
			url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}{r}.png',
		},
	},
	cartoDB_LightNoLabels: {
		key: 'cartoDB_LightNoLabels',
		thumbnail: 'cartoDB_VoyagerNoLabels',
		name: 'Bez popisků',
		type: 'wmts',
		options: {
			url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}{r}.png',
		},
	},
	esri_WorldImagery: {
		key: 'esri_WorldImagery',
		name: 'ESRI satelitní',
		type: 'wmts',
		options: {
			url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
			maxNativeZoom: 17,
		},
	},
	openStreetMap_Mapnik: {
		key: 'openStreetMap_Mapnik',
		name: 'OpenStreetMap',
		type: 'wmts',
		options: {
			url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
		},
	},
};
