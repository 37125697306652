import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const router = Select.router.getCurrent(state);
	const selectedFeatureKeyFromUrl =
		router?.params?.parsedQueryString?.featureId;
	const activeStyleKeyFromUrl = router?.params?.parsedQueryString?.styleKey;
	const activePathNameIsCase = router?.name === 'case';
	const selectedFeatureKeyFromSelections =
		Select.dromas.getSelectedFeatureKey(state);
	const featureGeometry = selectedFeatureKeyFromUrl
		? Select.dromas.getFeatureGeometryFromThematicLayerById(
				state,
				selectedFeatureKeyFromUrl
		  )
		: null;

	const defaultActiveStyle = Select.dromas.getDefaultActiveStyle(state);

	const activePeriodKeyFromUrl = router?.params?.parsedQueryString?.periodKey;
	const activePeriodKeyFromState = Select.periods.getActiveKey(state);
	const defaultActivePeriodKey = Select.dromas.getDefaultActivePeriodKey(state);

	return {
		activePathNameIsCase,
		activeStyleKeyFromUrl,
		defaultActiveStyle,
		selectedFeatureKeyFromSelections,
		selectedFeatureKeyFromUrl,
		featureGeometry,

		defaultActivePeriodKey,
		activePeriodKeyFromUrl,
		activePeriodKeyFromState,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setFeatureToURL: featureId => {
			dispatch(
				Action.dromas.updateAppUrl('case', {
					featureId,
					tabKey: 'dpbDetail',
				})
			);
		},
		setPeriodToURL: (periodKey, featureId) => {
			dispatch(
				Action.dromas.updateAppUrl('case', {
					periodKey,
					featureId,
				})
			);
		},
		setActivePeriodKey: periodKey => {
			dispatch(Action.periods.setActiveKey(periodKey));
		},
		zoomToFeatureGeometry: featureGeometry => {
			dispatch(Action.dromas.zoomToFeatureGeometry(featureGeometry));
		},
		addFeatureToSelection: featureId => {
			dispatch(
				Action.selections.setActiveSelectionFeatureKeysFilterKeys([featureId])
			);
		},
		setThematicLayerStyleKey: styleKey => {
			dispatch(Action.dromas.setThematicLayerStyleKey(styleKey));
		},
		setThematicLayerStyleKeyToURL: styleKey => {
			dispatch(Action.dromas.updateAppUrl('case', {styleKey}));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
