import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Actions from '../../../../../state/Action';
import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const router = Select.router.getCurrent(state);
	const activeStyleKey = router?.params?.parsedQueryString?.styleKey;

	return {
		styles: Select.dromas.getStylesForActiveCase(state),
		activeStyleKey: activeStyleKey,
		legendName: Select.dromas.getLegendAndChartsNameForActiveStyle(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		const componentKey = 'CaseStyle';

		return {
			onMount: () => {
				dispatch(
					Actions.styles.useIndexed(null, null, null, 1, 100, componentKey)
				);
			},
			onSelect: styleKey => {
				dispatch(Actions.dromas.updateAppUrl('case', {styleKey}));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
