import React from 'react';

export const LegendCategory = ({outlineColor, fillColor, label}) => {
	return (
		<div className="dromas-LegendCategory">
			<div
				className="dromas-LegendCategory-symbol"
				style={{borderColor: outlineColor, background: fillColor}}
			/>
			<div className="dromas-LegendCategory-label">{label}</div>
		</div>
	);
};

export const LegendCategoryLine = ({color, width, label}) => {
	return (
		<div className="dromas-LegendCategory">
			<div
				className="dromas-LegendCategory-symbol line"
				style={{height: `${width}px`, background: color}}
			/>
			<div className="dromas-LegendCategory-label">{label}</div>
		</div>
	);
};

export const LegendCategories = ({children}) => {
	return <div className="dromas-LegendCategories">{children}</div>;
};
