import React from 'react';
import DonutChart from '../chartComponents/DonutChart';

const dataCount = [
	{
		id: 'noMonitoring',
		label: 'Vyřazené DPB',
		value: 0.07,
		color: '#ff7f00',
	},
	{
		id: 'monitoring',
		label: 'Monitorované DPB',
		value: 0.93,
		color: '#aaaaaa',
	},
];

const dataArea = [
	{
		id: 'noMonitoring',
		label: 'Vyřazené DPB',
		value: 0.005,
		color: '#ff7f00',
	},
	{
		id: 'monitoring',
		label: 'Monitorované DPB',
		value: 0.995,
		color: '#aaaaaa',
	},
];

const NoMonitoringReasonSece = () => {
	return (
		<>
			<h4>Rozložení monitorovaných a vyřazených DPB</h4>
			<div className="dromas-Charts-section two-columns">
				<div>
					<h5>Podíl podle počtu DPB</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataCount} />
					</div>
				</div>
				<div>
					<h5>Podíl podle výměry DPB</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataArea} format=" >-.1%" />
					</div>
				</div>
			</div>
		</>
	);
};

export default NoMonitoringReasonSece;
