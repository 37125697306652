import React, {useEffect} from 'react';
import {find as _find} from 'lodash';
import classnames from 'classnames';
import propTypes from 'prop-types';
import moment from 'moment';
import enumerations from '../../../../../data/enumerations';

import './style.scss';

const AttributeRecord = ({
	name,
	nameInternal,
	description,
	value,
	unit,
	active,
	styleColor,
	activeCaseNameInternal,
}) => {
	const recordClasses = classnames('dromas-AttributeRecord', {
		'is-active': active,
	});

	const valueClasses = classnames('dromas-AttributeRecord-value', {
		'is-emphasized': nameInternal === 'NKOD_DPB',
	});

	let finalValue;

	// get enumeration values
	const enumerationRule =
		nameInternal === 'KULTURAKOD'
			? enumerations[nameInternal][activeCaseNameInternal]
			: enumerations[nameInternal];

	// use value from enumerations if exists
	if (enumerationRule) {
		finalValue = enumerationRule[value] || value || '-';
	}
	// special case for date
	else if (nameInternal === 'ActivityYearMonth') {
		if (value && value !== '-') {
			finalValue = moment(value, 'YYYYMM').format('MM/YYYY');
		} else {
			finalValue = '-';
		}
	}
	// round real numbers to two decimals
	else if (typeof value === 'number' && value % 1 !== 0) {
		finalValue = value.toFixed(2);
	} else if (value) {
		finalValue = value;
	} else {
		finalValue = '-';
	}

	return (
		<div className={recordClasses} title={description}>
			<span className="dromas-AttributeRecord-name">{name}</span>
			<span className={valueClasses}>
				{styleColor ? (
					<span
						className="dromas-AttributeRecord-value-color"
						style={{background: styleColor}}
					/>
				) : null}
				{finalValue}
			</span>
			<span className="dromas-AttributeRecord-unit">{unit}</span>
		</div>
	);
};

AttributeRecord.propTypes = {
	active: propTypes.bool,
	activeCaseNameInternal: propTypes.string,
	name: propTypes.string,
	nameInternal: propTypes.string,
	description: propTypes.string,
	value: propTypes.oneOfType([propTypes.string, propTypes.number]),
	unit: propTypes.string,
};

const AttributeSet = ({metadata, attributes, activeStyle, activeCase}) => {
	return (
		<div className="dromas-AttributeSet">
			<h5>{metadata?.data?.nameDisplay}</h5>
			{attributes &&
				attributes.map(attribute => {
					let styleColor;
					const value = attribute?.value;

					const attributeStyle =
						activeStyle &&
						_find(
							activeStyle,
							style => style.attributeKey === attribute?.metadata?.key
						);

					const styleForAttributeValue =
						attributeStyle?.attributeValues?.[value];
					if (styleForAttributeValue) {
						styleColor = styleForAttributeValue.fill;
					}

					return (
						<AttributeRecord
							key={attribute?.metadata?.key}
							activeCaseNameInternal={activeCase?.data?.nameInternal}
							name={attribute?.metadata?.data?.nameDisplay}
							nameInternal={attribute?.metadata?.data?.nameInternal}
							description={attribute?.metadata?.data?.description}
							unit={attribute?.metadata?.data?.unit}
							value={value}
							active={!!attributeStyle}
							styleColor={styleColor}
						/>
					);
				})}
		</div>
	);
};

AttributeSet.propTypes = {
	metadata: propTypes.object,
	activeCase: propTypes.object,
	activeStyle: propTypes.array,
	attributes: propTypes.array,
};

const AttributesList = ({
	activeCase,
	attributesGroupedBySet,
	thematicLayerStyle,
	onMount,
}) => {
	useEffect(() => {
		if (onMount) {
			onMount();
		}
	}, []);

	return (
		<div className="dromas-AttributesList">
			<div>
				{attributesGroupedBySet &&
					attributesGroupedBySet.map(attributeSet => {
						return (
							<AttributeSet
								metadata={attributeSet.metadata}
								attributes={attributeSet.attributes}
								activeCase={activeCase}
								activeStyle={
									thematicLayerStyle?.data?.definition?.rules?.[0]?.styles
								}
							/>
						);
					})}
			</div>
		</div>
	);
};

AttributesList.propTypes = {
	activeCase: propTypes.object,
	attributesGroupedBySet: propTypes.array,
	onMount: propTypes.func,
	thematicLayerStyle: propTypes.object,
};

export default AttributesList;
