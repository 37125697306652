import React from 'react';

import ActivityConfirmed from './sections/ActivityConfirmed';
import ActivityType from './sections/ActivityType';
import AnomalyType from './sections/AnomalyType';
import AnomalySubtype from './sections/AnomalySubtype';
import Management from './sections/Management';
import NoMonitoringReasonAnomalie from './sections/NoMonitoringReasonAnomalie';
import NoMonitoringReasonKluceni from './sections/NoMonitoringReasonKluceni';
import NoMonitoringReasonSece from './sections/NoMonitoringReasonSece';

import './style.scss';

const Charts = ({name}) => {
	let content;

	switch (name) {
		case 'activityConfirmed':
			content = <ActivityConfirmed />;
			break;
		case 'activityType':
			content = <ActivityType />;
			break;
		case 'anomalySubtype':
			content = <AnomalySubtype />;
			break;
		case 'anomalyType':
			content = <AnomalyType />;
			break;
		case 'management':
			content = <Management />;
			break;
		case 'noMonitoringReason-kluceni':
			content = <NoMonitoringReasonKluceni />;
			break;
		case 'noMonitoringReason-sece':
			content = <NoMonitoringReasonSece />;
			break;
		case 'noMonitoringReason-anomalie':
			content = <NoMonitoringReasonAnomalie />;
			break;
		default:
			content = null;
	}

	return <div className="dromas-Charts">{content}</div>;
};

export default Charts;
