import React from 'react';
import DonutChart from '../chartComponents/DonutChart';

const dataCount = [
	{
		id: 'noAnomaly',
		label: 'Bez anomálie',
		value: 0.88,
		color: '#4daf4a',
	},
	{
		id: 'anomaly',
		label: 'Anomálie zjištěna',
		value: 0.12,
		color: '#f73e60',
	},
];

const dataArea = [
	{
		id: 'noAnomaly',
		label: 'Bez anomálie',
		value: 0.89,
		color: '#4daf4a',
	},
	{
		id: 'anomaly',
		label: 'Anomálie zjištěna',
		value: 0.11,
		color: '#f73e60',
	},
];

const AnomalyType = () => {
	return (
		<>
			<h4>Výsledky monitoringu</h4>
			<div className="dromas-Charts-section two-columns">
				<div>
					<h5>Podíl podle počtu DPB</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataCount} />
					</div>
				</div>
				<div>
					<h5>Podíl podle výměry DPB</h5>
					<div className="dromas-ChartWrapper">
						<DonutChart data={dataArea} />
					</div>
				</div>
			</div>
		</>
	);
};

export default AnomalyType;
